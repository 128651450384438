

    .covid__planification {
        background-color: #175389;
        padding: 4rem;
        background-image: url(https://cdn.mde-content.com/website/gv2/covid/silhouettes.png);
        background-position: bottom;

        h2 {
            text-align: center;
            margin-bottom: 4rem;
            color: white;
            font-size: 2.8rem;
            font-weight: 900;
            text-transform: uppercase;
        }

        select {
            border-width: 0 0 2px 0;
            border-color: #175389;
            color: #175389;
            font-weight: bold;
        }

        .wrapper {
            max-width: 80rem;
            margin: 0 auto;
            box-shadow: 0 0 2px 0 #000;
        }

        .wrapper-select {
            max-width: 50rem;
            margin: 0 auto;
        }

        .graph {
            background-color: white;
            padding: 4rem;
        }

        canvas {
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }

        .canvas-wrapper {
            max-width: 50rem;
            margin: 0 auto;
            max-height: 25rem;
        }

        .btn-gauge__footfall {
            background-color: #175389;
            color: white;
            border-color: white;

            &:hover {
                background-color: white;
                border-color: white;
                color: #175389;
            }
        }

    }

    [id="modal-footfall"] {

        &.white-popup-block {
            background: #FFF;
            padding: 20px 30px;
            text-align: left;
            max-width: 900px;
            margin: 40px auto;
            position: relative;
            overflow: hidden;

        }

        .highcharts-figure {

            .chart-container {
                width: 400px;
                height: 300px;

                margin: 0 auto;
                background-image: url(https://cdn.mde-content.com/website/gv2/covid/silhouette_01.png);
                background-repeat: no-repeat;
                background-position: bottom left;
                background-size: 120px;

                @media screen and (max-width: 540px) {
                    width: 300px;
                    height: 300px;
                    background-position: bottom -0px left -20px;
                }

            }

            .round {
                height: 250px;
                width: 250px;
                position: absolute;
                background: #eaeffe;
                border-radius: 50%;
                left: 50%;
                margin-left: -125px;
                top: 24px;
            }
        }

        h5 {
            text-align: center;
            font-weight: normal;
            font-size: 3rem;
            color: #175389 !important;
            margin-bottom: 2rem;
        }

        .crowd {

            padding-top: 60px;


            .crowd-wrapper {
                background-image: url(https://cdn.mde-content.com/website/gv2/covid/silhouette_02.png);
                background-repeat: no-repeat;
                background-position: center left;
                background-size: 120px;

                @media screen and (max-width: 960px) {
                    background-size: 80px;
                }

                @media screen and (max-width: 540px) {

                }


                p {
                    padding: 5rem 1rem 5rem 150px ;
                    line-height: 3.2rem;
                    font-weight: bold;
                    font-size: 2rem;
                    color: #175389;


                    @media screen and (max-width: 540px) {
                        padding-left: 100px ;
                    }


                }

                b {
                    font-weight: bolder;
                    font-size: 5rem;
                    color: #175389 !important;
                    display: block;


                    @media screen and (max-width: 540px) {

                    }


                }
            }

        }

        .person {
            span {
                font-size: 4rem !important;
                color: #175389 !important;
                text-align: center;
                line-height: 3rem;

            }

            em {
                display: block;
                font-size: 2rem !important;
                font-style: normal;
            }

            .highcharts-text-outline {
                display: none;
            }
        }

        .white-popup-title {
            text-align: center;
            font-size: 3rem;
            color: #175389 !important;
            text-transform: uppercase;
        }

        .white-popup-content {
            padding: 2rem 0;
            clear: both;
        }

        .white-popup-btn {
            float: right;
        }

    }


body.GV2 {

}