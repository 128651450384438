/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
[class*=" btn-"],
[class^="btn-"] {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  border: 0;
  padding: .75em 1.5em;
  line-height: 1;
  text-decoration: none;
  transition: background-color 250ms ease-in, color 250ms ease-in;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: #fff;
  }

  /* Modifier */
  &.-wide {
    font-size: 1.4rem;
    padding: .75em 3em;
  }
}

.btn {
  &__icon {
    margin-bottom: .7em;
  }

  &-xl {
    padding: 1em 2em;
    font-size: 1em !important;
    border-width: 3px !important;
  }
}

.btn-primary {
  border: 1px solid $c-txt;
  background-color: transparent;
  color: $c-txt;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;

    &:hover,
    &:focus,
    &:active {
      background-color: $c-txt;
      color: #fff;
    }

  /* White */
  &.-white {
    border-color: #fff;
    color: #fff;

      &:hover,
      &:focus,
      &:active  {
        background-color: #fff;
        color: $c-txt;
      }
  }
}

.btn-secondary {
  border: 1px solid transparent;
  color: #fff;

  &:hover,
  &:focus,
  &:active  {
    background-color: #fff;
  }
}

/* Back button */
.btn-back {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  background-color: $c-txt;
  margin-bottom: 1rem;

  &::before {
    content: '<<';
    display: inline-block;
    speak: none;
    margin-right: 1rem;
    vertical-align: baseline;
  }

  @include mq($from: tablet) {
    .news-details &,
    .shop-desc & {
      position: absolute;
      bottom: 100%;
      left: 0;
    }
  }
}

.btn-block {
  width: 100%;
}
