.boxed-grid {
    position: relative;
    padding: 0 10%;
    width: 100%;

    &__background {
        bottom: 0;
        top: 0;
        background: #fff;
        opacity: .75;
        border-top: 16px solid #90c4a3;
        position: absolute;
        width: 80%;
        margin: -16px auto 0;
    }

    &__content {
        position: relative;
        padding-top: 3.5rem;
    }

    &__title {
        color: #90c4a3; // @todo use theme color ?
    }

    &__listing {
        margin: 0 12.5rem;

        li {
            padding-right: 0;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }

        article {
            border: 1px solid #90c4a3;
            background: #fff;

            &:hover, &:focus, &:active {
                border: 1px solid #639073;
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
            }

            figure {
                padding: 2rem 3.5rem;
                text-align: center;

                img {
                    filter: saturate(0);
                }
            }
        }
    }

    &__text {
        text-align: center;
        padding: 2rem 5.5rem;
        color: #5c5c5c; // @todo use theme color ?
    }

    &__buttons {
        padding-bottom: 4rem;
        text-align: center;
    }

    &-aside {
        padding: 0 .625em;
        display: flex;
        flex: 1 0 auto;
        width: 110rem;
        left: -6rem;
        align-items: center;

        .boxed-grid {
            &__content {
                margin: 0;
                width: 50%;
                padding-top: 5rem;
                padding-bottom: 5rem;
            }

            &__title {
                margin: 0 2rem 1rem 0;

                header.section__header {
                    text-align: left;
                }
            }

            &__listing {
                margin: 0 0 0 2rem;
            }

            &__text {
                text-align: left;
                padding: 0;
            }

            &__buttons {
                margin-top: 4rem;
                padding-bottom: 0;
                text-align: left;
            }
        }
    }

    @include mq($until: desktop) {
        padding: 0 5%;

        &__background {
            width: 90%;
        }

        &__title {
            header.section__header {
                h2.section__title {
                    font-size: 1.6em;
                    margin-right: 2.5rem;
                    margin-left: 2.5rem;
                }
            }
        }

        &__listing.-three {
            margin: 0 2rem;

            > li {
                width: 50%;

                &:nth-child(3n + 1) {
                    clear: none;
                }

                &:nth-of-type(1n+7) {
                    display: none;
                }
            }

            article {
                figure {
                    padding: 1rem 1.25rem;
                }
            }
        }

        &__text {
            padding: 2rem;
        }

        &-aside {
            width: 100%;
            left: 0;
            flex-wrap: wrap;

            .boxed-grid {
                &__content {
                    width: 100%;
                    padding-top: 3rem;
                    padding-bottom: 3rem;
                }

                &__buttons, &__text, &__title header.section__header {
                    text-align: center;
                }
            }
        }
    }
}
