/*------------------------------------*\
    #ICONS - SVG SPRITE
\*------------------------------------*/
[class^="icon-"],
[class*=" icon-"] {
    line-height: 1;
    width: 2.4rem;
    height: 2.4rem;
    fill: currentColor;
}

/** Overrides some default icons */
.fa-facebook { @extend .fa-facebook-f; }

/** Add new Font Awesome icon sizes */
.fa-1-5x { font-size: 1.5em; }
