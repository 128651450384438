/*------------------------------------*\
    #PRODUCT PAGE
\*------------------------------------*/

/**
* Details
*/
.product-details {
    @extend %clearfix;
    background-color: #fff;

    &__media {
        margin-bottom: 2rem;
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &__content {
        padding: 2rem;
        position: relative;

        p,
        ul,
        ol {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        text-align: center;
        font-size: 1.8rem;
        text-transform: uppercase;
        margin-bottom: 1.5em;
        line-height: 1.2;
        font-weight: 900;
    }

    &__body {
        font-family: $ff-serif;
        font-size: 1.5rem;
        text-align: center;
    }


    /* Prices */
    &__price {
        font-size: 1.4rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        margin: 1.5em 0;

        &-old {
            display: inline-block;
            vertical-align: middle;
            text-decoration: line-through;
        }

        &-new {
            display: inline-block;
            vertical-align: middle;
            color: #fff;
            font-size: 1.4285em;
            padding: 0 .5em;
            margin-left: 1rem;
        }
    }


    &__category {
        @include centerer(true, false);
        top: -4rem;
        display: inline-block;
        padding: 1em 2em;
        font-size: 1.1rem;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        text-align: center;
        min-width: 25rem;


        svg {
            width: 1.4rem;
            height: 1.4rem;
            margin-right: 1em;
        }
    }


    /* Add to favorites */
    .add-favorites {
        position: absolute;
        top: 0;
        right: 0;
    }

    /* Sharing */
    .sharing-toggle {
        position: absolute;
        top: 2.5rem;
        right: 0;
    }

    /* SALES */
    &__sales {
        display: inline-block;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        font-size: 2rem;
        transform: rotate(-45deg);
        padding: .125em 2em;
        position: absolute;
        top: 1.3rem;
        left: -3rem;

        &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: 95%;
            left: 0;
            background-color: inherit;
            width: 100%;
            padding-top: 100%;
        }
    }


    @include mq($from: tablet){
        &__category {
            top: -2rem;
        }

        &__media {
            width: 30%;
            float: left;
            margin-bottom: 0;
        }

        &__content {
            margin-left: 30%;
        }
    }


    @include mq($from: desktop) {
        &__media {
            width: 38%;
        }

        &__content {
            padding: 3em;
            margin-left: 38%;
        }
    }
}


/* Shop infos */
.product-details-shop {
    border: 1px solid $c-medgray;
    margin-top: 2em;
    padding: 4rem 2rem;

    &__logo {
        text-align: center;
    }

    &__infos {
        @extend %unstyled-list;

        li {
            min-height: 2.4rem;
            position: relative;
            padding-left: 4rem;

            + li {
                margin-top: 1.25em;
            }

            > svg {
                @include centerer(false, true);
                left: 0;
                fill: $c-darkgray;
            }
        }
    }


    @include mq($from: tablet){
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;

        &__logo {
            min-width: 40%;
            margin-bottom: 0;
        }
    }
}
