/*------------------------------------*\
    #SINGLE PAGE
\*------------------------------------*/
.single {
    font-size: 1.6rem;
    line-height: 1.4;
    background-color: #fff;
    padding: 2rem;

    h3,
    h4,
    h5,
    h6 {
        margin-top: 2em;

        + h3,
        + h4,
        + h5,
        + h6 {
            margin-top: 1em;
        }
    }

    /* Unordered list */
    ul {
        list-style-type: none;
        position: relative;
        margin-left: 3em;
        padding-left: 0;

        li:before {
            content: "\2022";
            position: absolute;
            left: -1.25em;
            margin-right: .5rem;
        }
    }

    /* Ordered list */
    ol {
        list-style-type: none;
        position: relative;
        margin-left: 3em;
        padding-left: 0;
        counter-reset: olCounter;

        li {
            counter-increment: olCounter;

            &:before {
                content: counter(olCounter)'.';
                position: absolute;
                left: -1.25em;
                margin-right: .5rem;
            }
        }
    }

    /* Table */
    table {
        width: 100%;
        background-color: #fff;
        border-collapse: collapse;
        font-size: 1.8rem;
        margin: 2em 0;

        thead {
            th {
                color: #fff;
                text-transform: uppercase;
                padding: 1.75em;
                font-size: 1.6rem;

                + th {
                    border-left: 1px solid $c-lightgray;
                }
            }
        }

        td {
            padding: .75em 1em;
            border-bottom: 1px solid $c-lightgray;

            + td {
                border-left: 1px solid $c-lightgray;
            }
        }

        tfoot {
            td {
                color: #fff;
                background-color: $c-darkgray;
                text-transform: uppercase;
                font-size: 1.4rem;
                padding: 1em;
                text-align: center;
            }
        }
    }


    @include mq($from: tablet){
        padding: 4rem;
    }
}
