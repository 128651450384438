/*------------------------------------*\
    #HIGHLIGHT CARD

\*------------------------------------*/

.highlight{
  padding-bottom: 3rem;
}

/* Slider */
.highlight-slider {
    position: relative;

    &__list {
        @extend %unstyled-list;
    }

    &__next,
    &__prev {
        @include centerer(false, true);
        width: 5.7rem;
        height: 5.7rem;
        border: 0;
        background-color: transparent;
        z-index: 200;
        margin-top: -3.2rem;
        display: none;

        /* 1. Show only if more than 1 item */
        &.is-visible {
            display: block; /* 1 */
        }

        &:hover,
        &:focus,
        &:active {
            color: #fff;
        }
    }

    &__next {
        right: 2rem;
    }

    &__prev {
        left: 2rem;
    }

    &__pager {
        margin-top: 1rem;
        text-align: center;

        span {
            display: inline-block;
            vertical-align: middle;
            width: 1rem;
            height: 1rem;
            text-indent: 200%;
            white-space: nowrap;
            border-radius: 50%;
            cursor: pointer;

            + span {
                margin-left: 2rem;
            }
        }
    }


    @include mq($from: xwide){
        &__next {
            left: calc(100% + 2rem);
            right: auto;
        }

        &__prev {
            right: calc(100% + 2rem);
            left: auto;
        }
    }
}


/* Card */
.highlight-card {
    position: relative;
    /*background-color: $c-primary;
    padding: 2em;*/
    box-shadow: 0 3px 5px rgba(#000, .15);

    &::before {
        /*content: '';*/
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px solid #fff;
        margin: 1em;
    }


    &__media {
        position: relative;
        /*margin: -2em;*/

        img {
            width: 100%;
        }
    }

    &__content {
        color: #fff;
        /*margin-top: 4em;*/
        text-align: center;
        position: relative;
    }

    &__title {
        font-family: $ff-serif;
        font-style: italic;
        margin-bottom: 0;

        &::after {
            content: '';
            display: block;
            width: 20rem;
            height: .2rem;
            background-color: #fff;
            margin: 2.5rem auto 2.4rem;
        }
    }

    &__summary {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1.5em;
    }


    @include mq($from: tablet){
        &__content {
            position: absolute;
            @include centerer(false, true);
            left: 2em;
            margin-top: 0;
            width: 50%;
        }

        &__title {
            font-size: 4.8rem;
        }

        &__summary {
            font-size: 1.25em;
        }
    }
}
