/*------------------------------------*\
    #BADGE
\*------------------------------------*/
.badge {
    display: inline-block;
    vertical-align: middle;
    padding: .8rem 1.5rem;
    line-height: 1.2;
    font-size: 1.2rem;
    background-color: #000;
    text-align: center;

    &__icon,
    &__label {
        display: inline-block;
        vertical-align: middle;
    }

    &__label {
        width: calc(100% - 7rem);
        margin-left: 1rem;
    }
}

.badges-list {
    @extend %unstyled-list;
    @include flexbox();
    justify-content: center;
    margin-left: -.3rem;
    margin-right: -.3rem;

    li {
        flex: 1;
        margin: .5rem .3rem;
        max-width: 50%;
    }

    .badge {
        width: 100%;
    }


    /* In shop infos block */
    .shop-infos & {
        margin-bottom: 2rem;
    }
}
