.gallery-slider {

    &__header {
        padding: 2rem .625em;
        margin: 0 -.625em 2rem;
        color: #fff;
    }

    &__slider {
        margin: 0 -.625em 1.5rem;

        .slick-prev,
        .slick-next {
            border: .2rem solid #fff;
            z-index: 100;
            padding: 0;
            margin: 0;
            position: absolute;
            top: 100%;
            width: 3rem;
            height: 3rem;
            transform: translateY(-50%);
            @extend %hide-text;

            &:before {
                content:'';
                display: block;
                width: 2.6rem;
                height: 2.6rem;
                -webkit-background-size: 2.6rem 2.6rem;
                background-size: 2.6rem 2.6rem;
            }
        }

        .slick-prev {
            left: 4rem;
            border-right: none;

            &:before {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj4gICAgPGc+ICAgICAgICA8cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJtMzQ0IDE1NWMwIDMtMSA1LTMgN2wtMTEyIDExMmwxMTIgMTEzYzIgMSAzIDQgMyA2YzAgMy0xIDUtMyA3bC0xNCAxNGMtMiAyLTUgMy03IDNjLTIgMC01LTEtNy0zbC0xMzMtMTMzYy0yLTItMy00LTMtN2MwLTIgMS00IDMtNmwxMzMtMTMzYzItMiA1LTMgNy0zYzIgMCA1IDEgNyAzbDE0IDE0YzIgMiAzIDQgMyA2eiIvPiAgICA8L2c+PC9zdmc+);
            }
        }

        .slick-next {
            left: 7rem;
            border-left: none;

            &:before {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48Zz48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJtMzM1IDI3NGMwIDMtMSA1LTMgN2wtMTMzIDEzM2MtMiAyLTUgMy03IDNjLTIgMC01LTEtNy0zbC0xNC0xNGMtMi0yLTMtNC0zLTdjMC0yIDEtNSAzLTZsMTEyLTExM2wtMTEyLTExMmMtMi0yLTMtNC0zLTdjMC0yIDEtNCAzLTZsMTQtMTRjMi0yIDUtMyA3LTNjMiAwIDUgMSA3IDNsMTMzIDEzM2MyIDIgMyA0IDMgNnoiLz48L2c+PC9zdmc+);
            }
        }
    }

    .-kids & {
        &__header {
            background-color: transparent;
            color: $c-txt;
        }
    }

    @include mq($from: tablet) {
        &__header {
            margin: 0 4rem 0 0;
        }

        &__slider {
            margin: 0 -4rem 1.5rem 0;
        }

        &__description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }

        .-kids & {
            &__header {
                margin-bottom: -3rem;
                padding-bottom: 8rem;
            }
        }
    }

    @include mq($from: desktop) {
        &__header {
            padding: 4rem 2rem;
        }

        &.-kids {
            position: relative;
            z-index: 10;
        }

        .-kids & {
            &__header {
                position: relative;
            }


            &__slider .slick-prev,
            &__slider .slick-next {
                background: $c-kids-secondary;

                &:before {
                    -webkit-background-size: 1.8rem 1.8rem;
                    background-size: 1.8rem 1.8rem;
                    background-repeat: no-repeat;
                    top: .4rem;
                    position: relative;
                    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxOSAxOSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTkgMTkiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZD0iTTQuOTMsOC43OWw4LjEzLTguMTNjMC40MS0wLjQxLDEuMDctMC40MSwxLjQ4LDBzMC40MSwxLjA3LDAsMS40OGwtNy41LDcuNWw3LjUsNy41YzAuNDEsMC40MSwwLjQxLDEuMDcsMCwxLjQ4DQoJcy0xLjA3LDAuNDEtMS40OCwwTDQuOTMsMTAuNUM0LjY5LDEwLjI3LDQuNiwxMC4wMyw0LjYsOS42NUM0LjYsOS4yOSw0LjY5LDkuMDMsNC45Myw4Ljc5eiIvPg0KPC9zdmc+DQo=);
                }
            }

            &__slider .slick-next {
                &:before {
                    top: -.4rem;
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
        }
    }
}
