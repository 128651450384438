/*------------------------------------*\
    #SUMMARY CARD
    Structure :
    - Header with BG (optional)
    - Image
    - Content :
        - Title
        - Summary
        - Call to Action
\*------------------------------------*/

.summary-card {
    background-color: #fff;
    position: relative;

    &__footered {
        padding-bottom: 5rem;
    }

    &__date_alternativ {
        display: inline-block;
        color: #fff;
        font-weight: bold;
        padding: 1rem;
        margin: 0 0 -4rem 0;
        position: relative;
        top: -4rem;

        &.-round {
            line-height: 1;
            padding-top: 1.25rem;
            margin: 0 0 -3rem 0;
            text-align: center;
            font-size: 1.5rem;
            width: 5.5rem;
            height: 5.5rem;
            overflow: hidden;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            font-weight: normal;

            .day {
                display: block;
                font-weight: bold;
            }
        }
    }

    &.loyalty {
        background-color: #000;
        color: #fff;à

        .summary-card__title a {
            color: #fff;
        }

        .summary-card__footer .btn-primary {
            &:hover,
            &:focus,
            &:active {
                color: #fff;
            }

            svg {
                height: 2em;
                width: 2em;
                margin: -.5em 1rem -.5em 0;
            }

            span {
                vertical-align: middle;
            }
        }
    }

    /* Header with BG (optional) */
    &__header {
        background-color: $c-darkgray;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.3rem;
        padding: 1.5rem;
        line-height: 1.2;

        svg {
            width: 1.8rem;
            height: 1.8rem;
            display: block;
            margin: 0 auto 1em;
        }
    }


    /* Image */
    &__media {
        background-color: #fff;

        img {
            width: 100%;
        }
    }


    /* Content */
    &__content {
        text-align: left;
        padding: 2rem 2.2rem;
        font-size: 1.7rem;
    }

    &__title {
        font-size: 1.6rem;
        font-family: $ff-sans-serif;
        margin-top: .4rem;
        margin-bottom: .2rem;
        color: $c-txt;

        a {
            color: $c-txt;
            text-decoration: none;
        }
    }

    &__label {
        color: #3991cf;
        font-size: 1.6rem;
    }

    &__summary {
        margin: 1.5em 0 0;

        p,
        ul,
        ol {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__category {
        color: #fff;
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: bold;
        position: absolute;
        padding: .5em 1em;
        top: 0;
        left: 50%;
        text-align: center;
        white-space: nowrap;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .badge__label {
            width: auto;
        }
    }

    &.loyalty {
        background-color: $c-darkgray;

        .summary-card__title {
            color: #fff;
        }

        .summary-card__badge {
            position: absolute;
            display: block;
            font-size: 1.5rem;
            text-align: center;
            border: 1px solid #fff;
            border-radius: 50px;
            width: 40px;
            height: 40px;
            line-height: 35px;
            right: 0;
            bottom: 0;

            & > svg {
                width: 2rem;
                color: #fff;
                text-align: center;
            }
        }
    }

    &__date {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.2rem;
        margin-top: 0;
        display: inline-block;
    }

    &__footer {
        position: absolute;
        bottom: 1.8rem;
        left: 2rem;
        right: 2rem;
    }

    /* Add to favorites */
    .add-favorites {
        position: absolute;
        top: 0;
        right: 0;
    }

    /* Sharing */
    .sharing-toggle {
        position: absolute;
        top: 2.5rem;
        right: 0;
    }

    /* SALES */
    &__sales {
        display: inline-block;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        font-size: 2rem;
        transform: rotate(-45deg);
        padding: .125em 2em;
        position: absolute;
        top: 1.3rem;
        left: -3rem;

        &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: 95%;
            left: 0;
            background-color: inherit;
            width: 100%;
            padding-top: 100%;
        }
    }

    /* Prices */
    &__price {
        font-size: 1.4rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        margin-bottom: 1.5em;

        &-old {
            display: inline-block;
            vertical-align: middle;
            text-decoration: line-through;
        }

        &-new {
            display: inline-block;
            vertical-align: middle;
            color: #fff;
            font-size: 1.4285em;
            padding: 0 .5em;
            margin-left: 1rem;
        }
    }


    /* Modifier */
    &.-highlight {
        margin-bottom: 0.625em;

        .summary-card__title {
            font-size: 2.4rem;
        }
    }

    &.-job {
        .summary-card__media {
            text-align: center;
            padding: 4rem;

            img {
                width: auto;
            }
        }
    }

    @include mq($until: tablet) {
        &__summary {
            display: none;
        }
    }

    &__blog {
        &--transition {
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            -ms-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
        }

        &:hover,
        &:active,
        &:focus {
            .summary-card {
                &__media {
                    img {
                        -webkit-transform: scale(1.07);
                        -moz-transform: scale(1.07);
                        -o-transform: scale(1.07);
                        transform: scale(1.07);
                    }
                }

                &__title {
                    color: #000;
                }

                &__short {
                    color: $c-darkgray;
                }
            }
        }

        .summary-card {
            &__media {
                overflow: hidden;

                img {
                    @extend .summary-card__blog--transition;
                }
            }

            &__date-overlay {
                top: -20px;
                text-transform: uppercase;
                letter-spacing: 1px;
                width: 55px;
                border: 2px solid;
                color: #fff;
                background-color: $c-darkgray;
                padding: 12px 0;
                text-align: center;
                z-index: 9;
                position: absolute;
                left: 20px;
                @extend .summary-card__blog--transition;

                span {
                    display: block;

                    &.day {
                        font-size: 17px;
                        line-height: 1em;
                        margin-bottom: 7px;
                    }

                    &.month {
                        font-size: 11px;
                        line-height: 1em;
                    }

                    &.year {
                        font-size: 11px;
                        line-height: 1em;
                        font-weight: 500;
                        margin-top: 7px;
                    }
                }
            }

            &__content {
                text-align: center;
                font-size: .95em;
                background: #fff;
            }

            &__label {
                font-size: .9em;
                text-transform: uppercase;
                @extend .summary-card__blog--transition;

                span {
                    padding-bottom: .8rem;

                    &:not(:last-of-type) {
                        &:after {
                            content: '-';
                            padding-left: .8rem;
                            padding-right: .6rem;
                        }
                    }
                }
            }

            &__title {
                font-weight: normal;
                letter-spacing: .05em;
                text-transform: uppercase;
                margin-top: .5rem;
                font-size: 1.2em;
                color: $c-darkgray;
                @extend .summary-card__blog--transition;
            }

            &__short {
                font-size: .9em;
                color: $c-medgray;
                padding: 2rem 2.2rem;
                @extend .summary-card__blog--transition;
            }

            &__footer {
                position: initial;
                bottom: initial;
                left: initial;
                right: initial;

                .summary-card__date {
                    font-weight: normal;
                }
            }
        }
    }
}

.aside {
    .summary-card {
        &__blog {
            .summary-card {
                &__title {
                    font-size: 1.6rem;
                    margin-top: 0;
                }

                &__short {
                    display: none;
                }
            }
        }
    }
}
