/*------------------------------------*\
    #HELPERS
\*------------------------------------*/


/**
* Notifications message
*/
%message {
    margin: 1em 0;
    padding: .5em 1em;
    border: 1px solid transparent;

    > *:last-child {
        margin-bottom: 0;
    }
}

@each $type, $color in $message-types {
    .message--#{$type} {
        @include message($color);

        &.-light {
            margin: 0;
            border: none;
            background-color: transparent;
        }
    }
}

@each $type, $color in $message-types {
    .txt--#{$type} {
        color: $color;
        font-weight: 600;
    }
}


/**
* Skip links
*/
.skip-link {
    position: absolute;
    left: -99999em;
    overflow: hidden;

    &:hover,
    &:focus {
        position: static;
    }
}



/**
* Align
*/
.txtleft   {
    text-align: left;
}

.txtright  {
    text-align: right;
}

.txtcenter {
    text-align: center;
}
.left,
.start {
    float: left;
}

img.left,
img.start {
    margin-right: 1em;
}

.right,
.end {
    float: right !important;
}

img.right,
img.end {
    margin-left: 1em;
}

img.left,
img.right,
img.start,
img.end {
    margin-bottom: 0.5em;
}


/*
 * Unstyled list - remove margin, padding and list type
 */
 %unstyled-list,
.unstyled-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ul.inline-list {
    @extend .unstyled-list;

    li {
        display: inline-block;
        vertical-align: middle;

        + li {
            margin-left: 1em;
        }
    }
}

/*
 * Unstyled button - remove background, border, padding
 */
%unstyled-button,
.unstyled-button {
    border: 0;
    background-color: transparent;
    padding: 0;
}



/*
 * Clearfix: contain floats
 */
 %clearfix:after,
 %clearfix:before,
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

%clearfix:after,
.clearfix:after {
    clear: both;
}


/**
* Hide text for bg img
*/
%hide-text {
    overflow: hidden;
    text-indent: 200%;
    white-space: nowrap;
}

.hide {
    display: none;
    
    &.active {
        display: block;
    }
}

/* Make hidden text accessible by screen readers */
%sr-only,
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
// Use in conjunction with .sr-only to only display content when it's focused.
%sr-only-focusable,
.sr-only-focusable {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        clip: auto;
    }
}

/* Hiden any DOM object */
.d-none, .btn.d-none {
    display: none;
}

/**
* Embeds responsive
*/
// Credit: Nicolas Gallagher and SUIT CSS.
.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;

    .embed-responsive__item,
    iframe,
    embed,
    object,
    video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        border: 0;
    }

    // Modifier class for 16:9 aspect ratio
    &.-ratio16by9 {
        padding-bottom: 56.25%;
    }

    // Modifier class for 4:3 aspect ratio
    &.-ratio4by3 {
        padding-bottom: 75%;
    }
}

.--no, section.--no, .section.--no {
    &-pad {padding: 0;}
    &-transform {text-transform: none;}
    &-link {text-decoration: none;}
}

/** Rotation animation */
@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}
