.search-form {
    &__title {
        text-align: center;
        color: #fff;
        font-family: $ff-serif;
        font-style: italic;
        font-weight: normal;
    }

    &__trigger {
        border: 0;
        margin: 0;
        padding: .4rem 0;
        background-color: transparent;
        visibility: visible;

        svg {
            fill: #fff;
        }
    }

    &-support {
        .search-form__trigger {
            position: absolute;
            bottom: 100%;
            margin-bottom: 5.2rem;
            right: 0;
            line-height: 1.1;

            svg {
                width: 1.6rem;
                height: 1.6rem;
            }
        }
    }

    &__close {
        display: block;
        border: 1px solid #fff;
        color: #fff;
        border-radius: 50%;
        padding: .5rem;
        margin: 2rem auto 0;
        background-color: transparent;
        line-height: 1;

        &:hover {
            background-color: #fff;
        }
    }

    &__form {
        padding: 0 1rem;
        display: flex;
        max-width: $container-mw/2;
        margin: 0 auto;

        .form-field {
            width: 100%;
            input {
                outline: none;
                width: 100%;
                border: 1px solid #fff;
                background-color: transparent;
                color: #fff;
            }

            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #fff;
            }
            ::-moz-placeholder { /* Firefox 19+ */
                color: #fff;
            }
            :-ms-input-placeholder { /* IE 10+ */
                color: #fff;
            }
            :-moz-placeholder { /* Firefox 18- */
                color: #fff;
            }
        }
    }

    &__submit {
        margin: 0;
        padding: 0 1rem;
        border: 1px solid #fff;
        border-left: 0;
        background-color: transparent;

        svg {
            fill: #fff;
        }
    }

    @include mq($until: desktop) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        visibility: hidden;

        &__container {
            padding: 3rem 2rem 2.5rem;

            &[aria-hidden="false"] {
                visibility: visible;
            }
        }

        &__trigger {
            position: absolute;
            top: -7rem;
            right: 4rem;

            svg {
                width: 1.8rem;
                height: 1.8rem;
            }
        }

        &-support {
            .search-form__trigger {
                right: 4rem;
                margin-bottom: 3.9rem;
            }
        }

        .has-search & {
            visibility: visible;
        }
    }

    @include mq($from: desktop) {
        display: inline-block;
        margin-left: 1rem;
        position: relative;

        &:before:not(.search-form-support) {
            content: '·';
            display: inline-block;
            margin-right: 1rem;
        }

        &__container {
            position: fixed;
            top: 12.4rem;
            left: 0;
            right: 0;

            &[aria-hidden="true"] {
                display: none;
            }
        }

        &__trigger {
            display: inline-block;
            padding-right: 0;
            padding-left: 0;
        }

        &__submit {
            background-color: transparent;
        }
    }
}

body.has-search {
    overflow: hidden;
    position: relative;

    &:after {
        content: '';
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        top: 9rem;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 101;
        // hover slider
    }
}
