.toolbar {
    position: fixed;
    z-index: 100;
    display: flex;
    bottom: 0;
    width: 100%;

    .item {
        background-color: #1a1a1a;
        height: 60px;
        text-align: center;
        flex: 0 0 20%;
        max-width: 20%;
        transition: all .3s ease;
        position: relative;

        a {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            width: 100%;
            text-decoration: none;
            display: block;
            color: #fff;

            svg {
                max-height: 25px;
                overflow: hidden;
                vertical-align: middle;
            }

            i {
                font-size: 1.2em;
            }

            .label-item {
                font-size: 9px;
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
                margin-top: 6px;
            }
        }
    }
}

@media (min-width: $screen-md) {
    .toolbar {
        width: 87px;
        bottom: inherit;
        right: 0;
        display: block;
        top: 50%;
        transform: translateY(-50%);

        .item {
            width: 87px;
            height: 76px;
            flex: 0 0 100% !important;
            max-width: 100% !important;
            margin-bottom: 11px;
        }
    }
}

@media (max-width: $screen-md) {
    .toolbar {
        background-color: #1a1a1a;
        justify-content: space-around;

        .item {
            &:hover {
                background-color: inherit;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .toolbar {
        .item {
            a {
                .label-item {
                    font-size: 11px;
                }
            }
        }
    }
}
