/*------------------------------------*\
    #MODAL
\*------------------------------------*/

.modal {
    background-color: #fff;
    padding: 4rem 3rem;
    position: relative;
    max-width: 30em;
    width: 95%;
    margin: 0 auto;

    &::before {
        content: '';
        margin: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px solid #000;
    }


    &__content {
        position: relative;
        z-index: 1;
    }

    &__title {
        text-align: center;
        text-transform: uppercase;
        font-size: 1.8rem;
    }

    .mfp-close {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        color: #fff;
        border-radius: 50%;
        top: -1rem;
        right: -1rem;
        opacity: 1;

        &:hover,
        &:focus,
        &:active {
            background-color: $c-darkergray;
            color: #fff;
        }
    }

    &.modal-map {
        max-width: $container-mw;
        margin-top: 12rem;
    }
}




/**
* MODAL
*/
.newsletter-modal-form {
    text-align: center;
    position: relative;
    margin: 2em auto 1em;
    max-width: 32rem;

    [type="email"] {
        width: 100%;
        padding-right: 10rem;
        text-align: left;
    }

    [type="submit"] {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 4.3rem;
        background-color: transparent;
        border: 0;
        border-left: 1px solid $c-medgray;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 0 1em;
        transition: background 250ms ease-in, color 250ms ease-in;

        &:hover,
        &:focus,
        &:active {
            background-color: $c-darkgray;
            color: #fff;
        }
    }
}

.white-popup {
    &-block {
        background: #FFF;
        padding: 20px 30px;
        text-align: left;
        max-width: 850px;
        margin: 40px auto;
        position: relative;
        overflow: hidden;
    }

    &-content {
        padding: 2rem 0;
        clear: both;
    }

    &-btn {
        float: right;
    }
}
