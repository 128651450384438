/*------------------------------------*\
    #TABS
\*------------------------------------*/
.tabs {
    @extend %unstyled-list;
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 5rem;

    li {
        display: inline-block;
        vertical-align: middle;
        margin: 1em 2rem;
    }

    .button,
    button {
        display: block;
        text-transform: uppercase;
        color: inherit;
        font-weight: bold;
        text-decoration: none;
        position: relative;
        background-color: transparent;
        border: 0;

        &::after {
            content: '';
            position: absolute;
            bottom: -1rem;
            left: 0;
            right: 0;
            height: .2rem;
            background-color: currentColor;
            transform: scaleX(0);
            transition: transform 250ms ease-in;
        }

        &:hover,
        &:focus,
        &:active {
            outline: 0;

            &::after {
                transform: scaleX(1);
            }
        }

        &.active {
            &::after {
                transform: scaleX(1);
            }
        }
    }

    svg {
        width: 2rem;
        height: 2rem;
        margin-right: .5em;
    }

    &.--badge {
        margin-bottom: 0;

        li {
            margin: .4rem;

            button {
                border: 1px solid #ececec;
                border-radius: 3px;
                padding: .7rem 1.3rem;
                line-height: 1;
                font-weight: normal;
                text-transform: none;
                transition: all 200ms ease-in-out;

                &::after {
                    content: none;
                }

                &.active {
                    border-color: $c-darkergray;
                    background: $c-darkergray;
                    color: #fff;
                }

                &:not(.active, .disabled) {
                    &:hover,
                    &:focus,
                    &:active {
                        border-color: $c-darkergray;
                        background: $c-darkergray;
                        color: #fff;
                    }
                }

                &.disabled {
                    opacity: .5;
                    cursor: default;
                }
            }
        }
    }
}

.-darkgray {
    .tabs {
        &.--badge {
            li {
                button {
                    border: none;
                    color: #fff;
                    background-color: lighten($c-darkgray, 12.5%);

                    &.active {
                        border-color: #ffffff;
                        background: #ffffff;
                        color: $c-darkgray;
                        box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 1);
                    }

                    &:not(.active, .disabled) {
                        &:hover,
                        &:active {
                            background: lighten($c-darkgray, 20%);
                        }
                    }
                }
            }
        }
    }
}
