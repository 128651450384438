/** Bottom design used for AB test ony. Not maintained. */
/*[data-ab-rgpd-consentement-design=A] {
    #tarteaucitronRoot {
        #tarteaucitronBack {
            background: $c-darkergray;
        }

        #tarteaucitronAlertBig {
            padding: 5rem 0;
            box-shadow: 0 -5px 75px #666;

            .tarteaucitronAlertBigWrapper {
                display: flex;
                align-items: center;

                #tarteaucitronDisclaimerAlert {
                    flex-grow: 2;

                    img {
                        text-align: left;
                        margin: 0 0 2rem;
                    }

                    h1, h2, h3, h4 {
                        font-weight: bold;
                        font-size: 1.3em;
                        margin-bottom: 1em;
                    }
                }

                .tarteaucitronAlertBigBtnWrapper {
                    flex-grow: 1;
                    max-width: 230px;
                    min-width: 230px;
                }
            }
        }
    }

    @include mq($until: tablet) {
        #tarteaucitronRoot {
            #tarteaucitronAlertBig {
                .tarteaucitronAlertBigWrapper {
                    #tarteaucitronDisclaimerAlert {
                        img {
                            text-align: center;
                            margin: 0 auto 3rem;
                        }
                    }
                }
            }
        }
    }
}*/

/** Modal design (chosen after AB test) */
body {
    #tarteaucitronRoot {
        #tarteaucitronBack {
            background-color: $c-darkergray;
            //display: block !important;
            z-index: 1000;
        }

        #tarteaucitronAlertBig {
            width: 860px;
            max-width: 85%;
            margin: 21px auto 0;
            top: 16px;
            right: 0;
            left: 0;
            bottom: auto;
            box-shadow: 0 0 35px #575757;
            padding: 0;

            .tarteaucitronAlertBigWrapper {
                padding: 5rem 7rem;

                #tarteaucitronDisclaimerAlert {
                    h1, h2, h3, h4 {
                        font-weight: normal;
                        font-size: 1.5em;
                        text-transform: uppercase;
                        margin-bottom: 2.2em;
                        text-align: center;
                    }
                }

                .tarteaucitronAlertBigBtnWrapper {
                    width: 100%;
                    margin: 3rem auto 0;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    flex-direction: row-reverse;
                    flex-wrap: wrap;

                    button {
                        max-width: 230px;
                    }
                }
            }
        }
    }
}

#tarteaucitronRoot {
    #tarteaucitronBack {
        outline: none;
    }

    #tarteaucitronAlertBig {
        background-color: #fff;

        .tarteaucitronAlertBigWrapper {
            @extend .container-fluid;

            #tarteaucitronDisclaimerAlert {
                display: block;

                img {
                    max-width: 15rem;
                    text-align: center;
                    margin: 0 auto 3rem;
                    display: block;
                }

                h1, h2, h3, h4 {
                    color: $c-darkgray;
                }

                p {
                    color: #5a5a5a;
                    line-height: 1.3em;
                }
            }

            .tarteaucitronAlertBigBtnWrapper {
                display: block;

                button#tarteaucitronPersonalize, button#tarteaucitronReject,  button#tarteaucitronCloseAlert, button#tarteaucitronPrivacyUrl {
                    display: block;
                    margin: 0 auto;
                    padding: .8rem;
                    text-align: center;
                    outline: none;
                    font-size: 1em;
                }

                button#tarteaucitronPersonalize {
                    background-color: #358bd6;
                    text-transform: uppercase;
                    font-size: 1.15em;
                    padding: 1.5rem 2.5rem;
                    transition: opacity 150ms ease-in-out;

                    &:hover {
                        opacity: .7;
                    }
                }

                button#tarteaucitronReject {
                    padding: .8rem;
                    transition: opacity 150ms ease-in-out;
                    &:hover {
                        opacity: .7;
                    }
                }

            }
        }
    }

    #tarteaucitron {
        top: 16px !important;

        #tarteaucitronClosePanel {
            width: 50px;
            top: 10px;
            z-index: 999;
            background: transparent;
            color: $c-medgray;
            outline: none;
        }

        #tarteaucitronServices {
            margin-top: 0!important;

            .tarteaucitronMainLine {
                background-color: #fff;
                border: none;
                padding: 1.5rem .5rem 3.5rem;

                &:hover {
                    background-color: #fff;
                }

                .tarteaucitronH1 {
                    color: $c-darkgray;
                    text-align: left;
                    margin: 2rem 3.5rem 0;
                    font-size: 1.3em;
                }

                #tarteaucitronPrivacyUrl {
                    display: none;
                }

                .tarteaucitronInfoBox {
                    background: #fff;
                    color: #5a5a5a;
                    border: none;
                    text-align: left!important;
                    font-weight: normal;
                    max-width: 100%!important;
                    margin: 0 3.5rem 4rem !important;
                    font-size: .8em!important;
                    padding: 0!important;
                }

                .tarteaucitronName {
                    margin-top: 0;

                    .tarteaucitronH2 {
                        color: $c-darkgray;
                        margin: 0 0 2rem 3.5rem !important;

                    }
                }
            }

            .tarteaucitronBorder {
                border: 0;
                padding-bottom: 3rem;

                &:before {
                    content: '';
                    border-bottom: 1px solid $c-lightgray;
                    width: 200px;
                    margin: 0 auto 4rem;
                    display: block;
                }

                .tarteaucitronHidden {
                    background-color: #fff;
                }

                > ul {
                    > li {
                        background: #fff;

                        > .tarteaucitronDetails {
                            display: block !important;
                            position: initial;
                            color: #5a5a5a;
                            max-width: 100%;
                            padding: 0;
                            margin: 0 3.5rem 2rem;
                            background: transparent;
                        }

                        > ul {
                            > li.tarteaucitronLine {
                                background: #fff;
                                padding: 1.5rem 1rem 1.5rem 5rem;

                                &:hover {
                                    background: #fff;
                                }

                                .tarteaucitronH3 {
                                    font-size: 1em;
                                }

                                .tarteaucitronListCookies, a {
                                    color: $c-medgray;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include mq($until: tablet) {
        #tarteaucitronAlertBig {
            padding: 10px!important;
            width: 860px  !important;
            max-width: 85%  !important;
            margin: 21px auto 0  !important;
            top: 16px  !important;
            right: 0  !important;
            left: 0  !important;
            bottom: auto  !important;
            box-shadow: 0 0 35px #575757  !important;
            position: relative;

            .tarteaucitronAlertBigWrapper {
                padding: 3.5rem !important;
                display: block !important;

                #tarteaucitronDisclaimerAlert {
                    h1, h2, h3, h4 {
                        font-weight: normal  !important;
                        text-transform: uppercase  !important;
                        text-align: center  !important;
                        margin-bottom: 1em  !important;
                        font-size: 1.3em  !important;
                    }

                    img {
                        margin: 0 auto 2rem;
                    }
                }

                .tarteaucitronAlertBigBtnWrapper {
                    margin: 3rem auto 0  !important;
                    text-align: center  !important;
                    display: flex  !important;
                    justify-content: center  !important;
                    flex-direction: row-reverse  !important;
                    flex-wrap: wrap  !important;
                    max-width: 100% !important;

                    button {
                        font-size: .85em  !important;
                        max-width: 230px  !important;
                    }

                    button#tarteaucitronPersonalize {
                        font-size: 1em  !important;
                    }
                }
            }
        }

        #tarteaucitron {
            max-width: 85% !important;
            margin: 0 auto !important;
            background: transparent;

            #tarteaucitronServices {
                .tarteaucitronMainLine {
                    .tarteaucitronInfoBox {
                        margin-bottom: 2.5rem !important;
                    }

                    .tarteaucitronName {
                        float: none;
                        display: block;
                        text-align: center;

                        .tarteaucitronH2 {
                            font-size: 1em !important;
                            font-weight: bold;
                            margin: 0 !important;
                        }
                    }

                    .tarteaucitronAsk {
                        margin-top: 1rem !important;
                        margin-right: auto !important;
                        margin-left: auto !important;
                        display: block;
                        float: none !important;

                        &:after {
                            content: '';
                            clear: both;
                            display: block;
                        }
                    }
                }

                .tarteaucitronBorder {
                    &:before {
                        margin: 0 auto 3rem;
                    }

                    > ul {
                        > li {
                            .tarteaucitronTitle {
                                button {
                                    font-weight: bold;
                                    font-size: 1em;
                                    text-align: center;
                                    width: 100%;
                                    margin-left: 0;
                                }
                            }

                            .tarteaucitronDetails {
                                text-align: center;
                                margin: 1rem 3.5rem;
                            }

                            > ul {
                                > li.tarteaucitronLine {
                                    padding: 1.5rem 2.5rem !important;
                                    border-left: 0 !important;

                                    .tarteaucitronName {
                                        margin-left: 0;
                                        width: 100% !important;
                                        text-align: center;
                                        float: none;

                                        .tarteaucitronH3 {
                                            font-size: .8em;
                                            text-align: center;
                                        }

                                        .tarteaucitronListCookies {
                                            font-size: .8em;
                                        }
                                    }

                                    .tarteaucitronAsk {
                                        margin-top: 1rem !important;
                                        margin-right: auto !important;
                                        margin-left: auto !important;
                                        display: block;
                                        float: none !important;

                                        &:after {
                                            content: '';
                                            clear: both;
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
