
.culture-carousel {
    &__list {
        margin-top: 2rem;
        padding: 0 1rem;

        .slick-prev,
        .slick-next {
            border: .2rem solid #fff;
            z-index: 100;
            padding: 0;
            margin: 0;
            position: absolute;
            bottom: -1.9rem;
            width: 3rem;
            height: 3rem;
            transform: translateY(-50%);
            top: 100%;
            @extend %hide-text;

            &:before {
                content:'';
                display: block;
                width: 2.6rem;
                height: 2.6rem;
                -webkit-background-size: 2.6rem 2.6rem;
                background-size: 2.6rem 2.6rem;
            }
        }

        .slick-prev {
            left: 4rem;
            border-right: none;

            &:before {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj4gICAgPGc+ICAgICAgICA8cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJtMzQ0IDE1NWMwIDMtMSA1LTMgN2wtMTEyIDExMmwxMTIgMTEzYzIgMSAzIDQgMyA2YzAgMy0xIDUtMyA3bC0xNCAxNGMtMiAyLTUgMy03IDNjLTIgMC01LTEtNy0zbC0xMzMtMTMzYy0yLTItMy00LTMtN2MwLTIgMS00IDMtNmwxMzMtMTMzYzItMiA1LTMgNy0zYzIgMCA1IDEgNyAzbDE0IDE0YzIgMiAzIDQgMyA2eiIvPiAgICA8L2c+PC9zdmc+);
            }
        }

        .slick-next {
            border-left: none;
            left: 7rem;

            &:before {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48Zz48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJtMzM1IDI3NGMwIDMtMSA1LTMgN2wtMTMzIDEzM2MtMiAyLTUgMy03IDNjLTIgMC01LTEtNy0zbC0xNC0xNGMtMi0yLTMtNC0zLTdjMC0yIDEtNSAzLTZsMTEyLTExM2wtMTEyLTExMmMtMi0yLTMtNC0zLTdjMC0yIDEtNCAzLTZsMTQtMTRjMi0yIDUtMyA3LTNjMiAwIDUgMSA3IDNsMTMzIDEzM2MyIDIgMyA0IDMgNnoiLz48L2c+PC9zdmc+);
            }
        }

        .slick-slide,
        > img {
            margin: $grid-gutter-width * 1em;
        }
    }
}
