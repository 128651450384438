/*------------------------------------*\
    #MOVIE
\*------------------------------------*/

/**
* LISTING
*/
.movies-list {
    @extend %unstyled-list;
    @extend .row;
    @include flexbox();
    justify-content: flex-start;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    > li {
        width: 50%;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        margin: 1.2rem 0;

        &[aria-hidden="true"] {
            display: none;
        }
    }

    @include mq($from: tablet){
        li {
            width: 25%;
        }
    }

    &:before {
        content: "";
        display: flex;
    }
}


.movie-details {
    background-color: #fff;
    padding: 2rem;
    color: $c-txt;
    font-size: 1.8rem;

    &__media {
        position: relative;
        margin-bottom: 2em;

        img {
            width: 100%;
        }

        figcaption {
            margin-top: 1em;
        }
    }

    &__trailer-cta {
        @include centerer();
        display: inline-block;
        border-radius: 50%;
        width: 10rem;
        height: 10rem;
        background-color: rgba($c-darkgray, .6);
        border: 1px solid #fff;
        transition: background-color 250ms ease-in;

        > svg {
            @include centerer();
            fill: #fff;
            width: 4rem;
            height: 4rem;
            margin-left: .5rem;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $c-darkgray;
        }
    }

    &__infos {
        @extend %clearfix;

        dt,
        dd {
            margin: .5rem 0;
            float: left;
        }

        dt {
            text-transform: uppercase;
            font-weight: 900;
            clear: left;
            margin-right: 1rem;
        }

        dd {
            &.-full {
                clear: both;
                float: none;
                width: 100%;
            }

            &.-synopsis {
                margin-bottom: 1em;
            }
        }

    }

    &__next-show {
        font-weight: bold;
        color: map-get($message-types, error);
    }


    &__hours {
        @extend %unstyled-list;
        @extend %clearfix;

        &[aria-hidden="true"] {
            display: none;
        }

        li {
            float: left;
            width: 10rem;
            height: 10rem;
            background-color: map_get($message-types, error);
            -webkit-transition: background-color .3s;
            -moz-transition: background-color .3s;
            -ms-transition: background-color .3s;
            -o-transition: background-color .3s;
            transition: background-color .3s;
            margin: .1rem 0 0 .1rem;
            text-align: center;
            color: #fff;
            font-size: 1.4rem;
            padding: 1.5rem 1rem;

            &:hover {
                background-color: lighten(map_get($message-types, error), 5%);
            }
        }

        strong {
            display: block;
            font-size: 1.4285em;
        }

        a {
            display: block;
            text-decoration: none;
            color: inherit;
            height: 100%;
        }
    }



    @include mq($from: tablet){
        &__media,
        &__content {
            display: inline-block;
            vertical-align: top;
        }

        &__media {
            width: calc(35% - .25em);
            margin-bottom: 0;
        }

        &__content {
            width: calc(65% - .25em);
            padding-left: 2rem;
        }
    }

    @include mq($from: desktop){
        padding: 5rem;

        &__media {
            width: calc(42% - .25em);
        }

        &__content {
            width: calc(58% - .25em);
            padding-left: 5rem;
        }
    }
}

.movie {
    &__poster {
        width: 100%;
        position: relative;
        padding-right: 3rem;
        padding-left: 3rem;

        img {

        }

        &-trailer {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 3rem;
            right: 3rem;
            text-align: center;
            background: rgba(0, 0, 0, 0.45);
            transition: all .3s ease-in-out;
            opacity: 0;

            a {
                display: block;
                color: #ffffff;
                text-decoration: none;
                font-weight: bold;
                text-transform: uppercase;
                font-size: .8em;
                padding-top: 55%;
                height: 100%;

                .btn__icon {
                    margin-bottom: 0.7rem;
                    font-size: 4em;
                }
            }
        }

        &:hover, &:active, &:focus {
            .movie__poster-trailer {
                opacity: 1;
            }
        }
    }

    &__header {
        margin-top: 5rem;
        margin-bottom: 4rem;
        font-size: 1.5em;
        letter-spacing: .03em;
    }

    &__title {
        font-size: 1em;
        display: inline-block;
        padding-right: 1em;
        margin-bottom: .35em;
    }

    &__director {
        font-size: .55em;
        display: inline-block;
        text-transform: uppercase;
        color: $c-medgray;
        font-weight: bold;
    }

    &__genres {
        font-size: .55em;
        letter-spacing: 0;

        &-item {
            display: inline-block;
            vertical-align: middle;
            padding: 0.3rem 1rem;
            line-height: 1.3;
            text-align: center;
            border: 1px solid $c-darkgray;
            border-radius: 3px;
            color: $c-darkgray;
        }
    }

    &__synopsis {
        margin-bottom: 4rem;
    }

    &__metas {
        &-item {
            display: flex;
            justify-content: flex-start;

            > div {
                margin-right: 1em;

                &:first-of-type {
                    font-weight: bold;
                    min-width: 110px;
                    width: 110px;
                }
            }
        }
    }

    &__showtimes {
        margin-top: 4rem;

        h1, h2, h3 {
            font-size: 1.2em;
            letter-spacing: 0.03em;
        }

        h4, h5, h6 {
            font-size: 1em;
            letter-spacing: 0.03em;
        }

        &-dates, &-sessions {
            @extend %unstyled-list;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 4rem;

            > li.item {
                margin: .5rem;

                .card {
                    font-size: 1.6em;
                    border: 1px solid $c-lightergray;
                    border-radius: 3px;
                    padding: 1rem;
                    transition: all .3s ease-in-out;
                }
            }
        }

        &-dates {
            text-transform: uppercase;

            > li.item {
                .card {
                    display: block;
                    outline: 0;
                    text-transform: uppercase;

                    span {
                        display: block;
                        font-weight: bold;
                        line-height: 1.5;

                        &:first-of-type {
                            color: #999;
                        }

                        &:last-of-type, &:first-of-type {
                            font-size: .5em;
                            font-weight: normal;
                        }
                    }

                    &.active {
                        span {
                            color: #fff;
                        }
                    }
                }
            }
        }

        &-sessions {
            > li.item {
                display: none;

                .card {
                    display: block;
                    text-decoration: none;
                    color: $c-txt;

                    .session {
                        &-lg {
                            display: block;
                            font-weight: bold;
                            line-height: 1.5;
                            color: #999;
                            font-size: .5em;
                        }

                        &-hour {
                            display: block;
                            font-weight: bold;
                        }

                        &-tags {
                            @extend %unstyled-list;
                            width: 100%;
                            font-size: 0.5em;
                            margin-top: 1rem;

                            > li {
                                color: #fff;
                                font-weight: bold;
                                font-size: 0.8em;
                                padding: 0.5rem 1.1rem;
                                border-radius: 3px;
                                margin: .5rem;
                            }
                        }
                    }
                }
            }
        }
    }

    @include mq($until: mobile) {
        &__header {
            text-align: center;
        }

        &__title {
            padding-right: 0;
            display: block;
            margin-bottom: 0;
        }

        &__director {
            display: block;
        }
    }
}
