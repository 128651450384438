/*------------------------------------*\
    #LISTING OF ELEMENTS
\*------------------------------------*/
.listing {
  @extend %unstyled-list;
  @extend .row;

  &:after, &:before {
    clear: both;
    content: '';
    display: block;
  }

  &__link {
    display: block;
    text-decoration: none;
    transition: box-shadow 0.1s ease-in-out;

    &:hover {
      box-shadow: 0 5px 15px rgba(230, 230, 230, 0.4);
    }
  }

  > li {
    padding-left: ($grid-gutter-width / 2) * 1em;
    padding-right: ($grid-gutter-width / 2) * 1em;
    margin-bottom: ($grid-gutter-width) * 1em;
  }

  &.-six {
    > li {
      float: left;
      width: 50%;

      &:nth-child(2n + 1) {
        clear: left;
      }
    }
  }

  &__flex-item {
    &:nth-child(even) {
      background-color: #F3EBEA;
    }

    .flex-item {
      &__container {
        @extend .container-fluid;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding-top: 7.5rem;
        padding-bottom: 7.5rem;

        > div {
          width: 50%;
        }

        .item-infos {
          padding-left: 7.5rem;

          &__title {
            margin-bottom: 3.5rem;

            h3 {
              margin-bottom: .8rem;

              &:before {
                content: '';
                border: 1px solid #333;
                height: 2px;
                position: absolute;
                width: 30px;
                margin-left: -5.5rem;
                margin-top: 1.2rem;
              }
            }

            .baseline {
              color: rgb(110, 198, 212);
              font-size: 1.8rem;
            }
          }

          .summary {
            font-size: 1.06em;
            padding-right: 2rem;
            margin-bottom: 3rem;
          }
        }

        &.--odd {
          flex-direction: row-reverse;

          .item-infos {
            padding-left: 0;
            padding-right: 7.5rem;
            text-align: right;

            &__title {
              text-align: right;

              h3 {
                &:before {
                  content: none;
                }

                &:after {
                  content: '';
                  border: 1px solid #333;
                  height: 2px;
                  position: absolute;
                  width: 30px;
                  margin-left: 2.5rem;
                  margin-top: 1.2rem;
                }
              }
            }

            .summary {
              padding-right: 0;
              padding-left: 2rem;
            }
          }
        }
      }
    }

    @include mq($until: wide) {
      .flex-item {
        &__container {
          padding: 4rem 2rem !important;
          flex-direction: column-reverse !important;

          > div {
            width: 100%;
          }

          .item-infos {
            padding-left: 0 !important;
            padding-right: 0 !important;
            text-align: left !important;

            &__title {
              text-align: left !important;

              h3 {
                &:before, &:after {
                  content: none !important;
                }
              }
            }

            .summary {
              padding: 0 !important;
            }
          }

          &.--odd {
          }
        }
      }
    }
  }

  &.shops-list {
    > li {
      float: none;
      width: 100%;

      &:nth-child(2n + 1) {
        clear: none;
      }
    }
  }

  @include mq($until: phablet) {
    &.-three {
      > li {
        float: left;
        width: 50%;

        &:nth-child(2n + 1) {
          clear: left;
        }
      }
    }
  }

  @include mq($until: mobile) {
    &.-three {
      > li {
        width: 100%;
      }
    }
  }

  @include mq($from: phablet) {
    &.-two,
    &.-four {
      > li {
        float: left;
        width: 50%;

        &:nth-child(2n + 1) {
          clear: left;
        }
      }
    }

    &.-three {
      > li {
        float: left;
        width: 33.3333%;

        &:nth-child(2n + 1) {
          clear: none;
        }

        &:nth-child(3n + 1) {
          clear: left;
        }
      }
    }

    /*
    * Trends listing layout
    * First bigger.
    * 5 items
    */
    &.-trends {
      > li {
        float: left;
        width: 33.3333%;

        &.-big {
          width: 66.66666%;
        }

        &:nth-child(3) {
          clear: left;
        }
      }
    }

    &.shops-list {
      > li {
        float: left;
        width: 50%;
      }
    }
  }

  @include mq($from: tablet) {
    &.-six {
      > li {
        width: 25%;

        &:nth-child(2n + 1) {
          clear: none;
        }

        &:nth-child(4n + 1) {
          clear: left;
        }
      }
    }

    /*
    * Restaurant suggestions
    * First bigger on right.
    * 5 items.
    */
    &.-suggestions {
      position: relative;

      > li {
        width: 25%;
        float: left;
        margin-bottom: 2rem;

        &.-big {
          width: 50%;
          float: left;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 1rem;
            left: .5rem;
            width: calc(50% - 1rem);
            background-color: #fff;
          }
        }
      }
    }

    &.shops-list {
      > li {
        float: left;
        width: 33.3333%;
        clear: none;

        &:nth-child(2n + 1) {
          clear: none;
        }

        &:nth-child(3n + 1) {
          clear: left;
        }
      }
    }
  }

  @include mq($from: desktop) {
    > li {
      padding-right: 1rem;
      padding-left: 1rem;
      margin-bottom: 2rem;
    }

    &.-four {
      > li {
        width: 25%;

        &:nth-child(2n + 1) {
          clear: none;
        }

        &:nth-child(3n + 1) {
          clear: none;
        }

        &:nth-child(4n + 1) {
          clear: left;
        }
      }
    }

    &.-six {
      > li {
        width: 16.666666%;

        &:nth-child(4n + 1) {
          clear: none;
        }

        &:nth-child(6n + 1) {
          clear: left;
        }
      }
    }
  }
}

.masonry-listing {
  &--three {
    width: 30%;
    &-gutter { width: 3%; }
  }
  &--two {
    width: 45%;
    &-gutter { width: 5%; }
  }
  &--one {
    width: 100%;
    &-gutter { width: 0; }
  }

  &__item {
    margin-bottom: 4rem;
  }

  &__sizer,
  &__item {
    @extend .masonry-listing--two;
  }

  &__gutter {
    @extend .masonry-listing--two-gutter;
  }

  @include mq($until: mobile) {
    &--two {
      width: 100%;
      &-gutter { width: 0; }
    }
  }
}

.-darkgray {
  .listing {
    &__link {
      &:hover {
        box-shadow: 0 3px 5px #232323;
      }
    }
  }
}

.listing-filters {
  text-align: center;

  &:last-of-type {
    margin-bottom: 5rem;
  }

  ul.tabs {
    li {
      a {
        display: block;
        text-transform: uppercase;
        color: inherit;
        font-weight: bold;
        text-decoration: none;
        position: relative;
        background-color: transparent;
        border: 0;
      }

      a.listing-filter {
        &::after {
          content: '';
          position: absolute;
          bottom: -1rem;
          left: 0;
          right: 0;
          height: .4rem;
          background-color: currentColor;
          transform: scaleX(0);
          transition: transform 250ms ease-in;
        }

        &:hover,
        &:focus,
        &:active {
          outline: 0;

          &::after {
            transform: scaleX(1);
          }
        }

        &.active {
          &::after {
            transform: scaleX(1);
          }
        }
      }
    }
  }

  &.--boxed {
    background: #fff;
    position: relative;
    box-shadow: 0 10px 25px -25px rgba(0, 0, 0, .3);

    ul.tabs {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;

      li {
        margin: 1em;
        flex-basis: 5%;

        a {
          color: $c-darkergray;

          sup {
            position: absolute;
            background: red;
            color: #fff;
            text-align: center;
            right: 0;
            border-radius: 50px;
            line-height: 1;
            width: 17px;
            height: 17px;
            font-weight: bold;
            padding-top: .3em;
          }
        }

        button {
          color: darken($c-medgray, 15%);

          &.active {
            color: $c-darkgray;
          }

          &.disabled {
            opacity: .4;
            cursor: default;

            &::after {
              content: none;
            }
          }
        }
      }
    }
  }

  @include mq($until: tablet) {
    margin-bottom: 3rem;

    select.js-listing-filter + .tabs {
      display: none;
    }
  }
  @include mq($from: tablet) {
    select.js-listing-filter {
      display: none;
    }
  }
}

/** Alter listings in the aside column */
.aside {
  .listing {
    &.-six, &.-four, &.-three, &.-two, &.-one, &.-suggestions {
      > li {
        width: 100%;
        float: none;
      }
    }

    &.-suggestions {
      margin-bottom: 1rem;
    }
  }
}
