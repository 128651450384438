/*------------------------------------*\
    #CINE CARD
    Structure :
    - Image
        - CTA to trailer
    - Content :
        - Title
        - Time
        - Next show
        - Hours

    Card for cinema movie.
\*------------------------------------*/

.cine-card {
    position: relative;
    overflow: hidden;

    &--transition {
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
    }

    a {
        color: #fff;
        text-decoration: none;
        display: block;
    }

    /* Image */
    &__media {
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            @extend .cine-card--transition;
        }
    }

    &__trailer-cta {
        @include centerer();
        display: inline-block;
        border-radius: 50%;
        width: 5.5rem;
        height: 5.5rem;
        background-color: rgba($c-darkgray, .6);
        border: 1px solid #fff;
        transition: all 130ms ease-in;

        > svg {
            @include centerer();
            fill: #fff;
            margin-left: .125em;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $c-darkgray;
        }
    }


    /* Content */
    &__content {
        font-size: 1.4rem;
        background-color: $c-darkergray;
        color: #fff;
        padding: 2rem;
        @extend .cine-card--transition;
    }

    &__filters {
        margin-top: 1.3rem;

        span.badge {
            color: #fff;
            font-weight: bold;
            font-size: .8em;
            padding: 0.5rem 1.1rem;
            border-radius: 3px;
        }
    }

    &__title {
        h3 {
            text-transform: none;
            font-size: 1.6rem;
            margin-bottom: 0.3rem;
            letter-spacing: 0.05em;
        }

        p {
            margin-top: 0;
        }
    }

    &__next-show {
        color: $c-lightgray;
        font-size: .9em;
    }

    &__hours {
        @extend %unstyled-list;

        li {
            display: inline-block;
            vertical-align: middle;

            &:not(:first-child) {
                &::before {
                    content: ' - ';
                    padding: 0 .25em;
                }
            }
        }
    }

    &__more {
        display: inline-block;
        padding: .25em 1.5em;
        text-transform: uppercase;
        border: 1px solid $c-medgray;
        color: $c-lightgray;
        text-decoration: none;
        font-size: 1.2rem;
        transition: background-color 250ms ease-in, color 250ms ease-in;

        &:hover,
        &:focus,
        &:active {
            background-color: $c-lightgray;
            color: $c-txt;
        }
    }

    &__ribbon {
        background-color: map-get($message-types, error);
        color: #fff;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        padding: .625em 1em;
        position: absolute;
        top: 3rem;
        left: -16rem;
        right: 0;
        transform: rotate(-25deg);

        svg {
            width: 1.6rem;
            height: 1.6rem;
            margin: 0 .5em;
        }
    }

    &:hover,
    &:focus,
    &:active {
        .cine-card {
            &__media {
                img {
                    -webkit-transform: scale(1.07);
                    -moz-transform: scale(1.07);
                    -o-transform: scale(1.07);
                    transform: scale(1.07);
                }
            }

            &__content {
                background-color: lighten($c-darkergray, 10%);
            }
        }
    }
}

/** Override based on a special container */
.slick {
    .cine-card {
        margin: 0 1.5rem;
    }
}

/** Override some details based on the current section background color */
.-white {
    .cine-card {
        &__content {
            background-color: $c-darkgray;
        }

        &:hover,
        &:focus,
        &:active {
            .cine-card {
                &__content {
                    background-color: lighten($c-darkgray, 10%);
                }
            }
        }
    }
}
