
.kids-tiles {
    position: relative;
    padding: 0 0 2rem;

    background-color: rgba(255, 255, 255, .7);
    z-index: 1;

    &__item {
        text-align: center;
        margin-bottom: 2rem;
        width: 100%;
    }

    .-details & {
        padding: 0;
    }

    @include mq($from: tablet) {
        padding: 0 0 4rem;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;

        &__col {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin: 0 1.5rem;
        }

        &__row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            .kids-tiles__col & {
                flex: 1;
            }

            &:nth-child(n+2) {
                margin-top: 2rem;
            }
        }

        &__item {
            width: 95%;
        }
    }

    @include mq($from: desktop) {
        padding: 0 4rem 4rem;

        &__item {
            width: 26rem;
        }
    }
}

.kids-tile {
    position: relative;
    transition: box-shadow 0.1s ease-in-out;

    &:hover {
        box-shadow: 0 3px 5px #dfdfdf;
    }

    &__background {
        object-fit: cover;
    }

    &.-small {

        &:last-child {
            margin-right: 0;
        }

        @include mq($until: tablet) {
            margin: 0 0 2rem 0;
            width: 100% !important;
        }

        @include mq($from: tablet) {
            margin-right: 2rem;
        }
    }

    figure {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, .15);
        }
    }

    &__link {
        text-decoration: none;
        font-size: 1.8rem;
        color: #fff;
        padding: 5rem 4rem;
        display: block;
        font-family: $ff-sans-serif;
        text-shadow: 1px 1px 0 #3c3c3c;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .3rem;

        //span {
        //    background-color: #fff;
        //    color: #666;
        //    padding: .6rem 1.8rem;
        //    text-transform: uppercase;
        //    font-size: 1.8rem;
        //    letter-spacing: .2rem;
        //    text-shadow: 0 0 0 #666;
        //}

        .kids-tile__background + & {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2rem;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        em {
            display: block;
            font-size: 3.6rem;
            font-style: normal;
        }

        .-big & {
            padding: 8rem 4rem;
        }

        .-small & {
            font-size: 2rem;
            padding: 4rem 3rem;
            font-family: $ff-heading;

            em {
                font-size: 2rem;
                font-weight: bold;
            }
        }

        .-colored & {
            background-color: $c-kids-secondary;
            color: $c-txt;
        }

        .-venue & {
            background-color: $c-kids;
            color: $c-txt;
        }

        .-age & {
            background-color: $c-kids-secondary;
            color: $c-txt;
        }
    }

    @include mq($from: desktop) {
        &.-balloon1 {
            &:before {
                content: "";
                position: absolute;
                background-image: url('../../images/kids/balloons_1.png');
                background-repeat: no-repeat;
                width: 107px;
                height: 265px;
                top: -120px; right: 40px;
                z-index: -1;
            }
        }

        &.-balloon2 {
            &:before {
                content: "";
                position: absolute;
                background-image: url('../../images/kids/balloons_2.png');
                background-repeat: no-repeat;
                width: 120px;
                height: 120px;
                top: 0; left: -75px;
                z-index: 2;
            }
        }

        &.-balloon2-alt {
            &:before {
                content: "";
                position: absolute;
                background-image: url('../../images/kids/balloons_2.png');
                background-repeat: no-repeat;
                width: 107px;
                height: 100px;
                bottom: -30px; right: 30px;
                z-index: 2;
            }
        }

        &.-balloon4 {
            &:before {
                content: "";
                position: absolute;
                background-image: url('../../images/kids/balloons_4.png');
                background-repeat: no-repeat;
                width: 107px;
                height: 100px;
                bottom: -55px; left: -80px;
                z-index: 2;
            }
        }


        &.-balloon4 {
            &:before {
                content: "";
                position: absolute;
                background-image: url('../../images/kids/balloons_4.png');
                background-repeat: no-repeat;
                width: 107px;
                height: 100px;
                bottom: -30px; right: 30px;
                z-index: 2;
            }
        }

        &.-balloon5 {
            &:before {
                content: "";
                position: absolute;
                background-image: url('../../images/kids/balloons_5.png');
                background-repeat: no-repeat;
                width: 55px;
                height: 55px;
                bottom: -30px; right: 30px;
                z-index: 2;
            }
        }

        &.-ink1 {
            &:after {
                content: "";
                position: absolute;
                background-image: url('../../images/kids/ink_1.png');
                background-repeat: no-repeat;
                width: 103px;
                height: 103px;
                bottom: -50px; right: -40px;
                z-index: 2;
            }
        }

        &.-ink2 {
            &:after {
                content: "";
                position: absolute;
                background-image: url('../../images/kids/ink_2.png');
                background-repeat: no-repeat;
                width: 81px;
                height: 161px;
                top: -65px; right: -45px;
                z-index: -1;
            }
        }

        &.-ink4 {
            &:after {
                content: "";
                position: absolute;
                background-image: url('../../images/kids/ink_4.png');
                background-repeat: no-repeat;
                width: 123px;
                height: 64px;
                bottom: -40px; left: -50px;
                z-index: -1;
            }
        }
    }
}
