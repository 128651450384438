.live-content {
    text-align: center;



    &__media {
        border: .1rem solid $c-lightergray;
    }

    &__store {
        a {
            display: block;
            text-align: center;
            color: #fff;
            padding: 1.5rem;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.9rem;
            transition: background .3s;

            &:hover, &:focus {
                background: $c-txt;
            }
        }
    }
}
