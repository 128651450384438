@charset "utf-8";

@import "~select2/dist/css/select2.min.css";
@import "utilities/variables";

@import "utilities/mixins";
@import "utilities/helpers";
@import "utilities/sass-mq";
@import "utilities/responsive-utilities";
@import "utilities/utilities";

@import "base/normalize";
@import "base/base";
@import "base/typography";

@import "layout/grids";
@import "layout/listing";
@import "layout/header";
@import "layout/navigation";
@import "layout/footer";
@import "layout/form";
@import "layout/aside";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/duotone";
@import "~@fortawesome/fontawesome-pro/scss/brands";

@import "components/buttons";
@import "components/icons";
//@import "components/map";
@import "components/card";
@import "components/alert";
@import "components/summary-card";
@import "components/pop-card";
@import "components/cine-card";
@import "components/img-card";
@import "components/logo-card";
@import "components/highlight-card";
@import "components/quick-infos";
@import "components/services-list";
@import "components/tabs";
@import "components/visit-us-block";
@import "components/newsletter-banner";
@import "components/newsletter-simple";
@import "components/newsletter-cta";
@import "components/vote-cta";
@import "components/event-cta";
@import "components/pics-gallery";
@import "components/misc";
@import "components/tags";
@import "components/mfp";
@import "components/modal";
@import "components/badge";
@import "components/autocomplete";
@import "components/responsive-embed";
//@import "components/map-viewer";
@import "components/jquery-eu-cookie-law-popup";
@import "components/live-content";
@import "components/sitemap";
@import "components/search-form";
@import "components/search-result";
@import "components/language-switcher";
@import "components/shop-card";
@import "components/foodcourt-hours";
@import "components/slick";
@import "components/foodcourt-list";
@import "components/abc-book";
@import "components/culture-card";
@import "components/culture-header";
@import "components/culture-slider";
//@import "components/culture-map";
@import "components/kids-tiles";
@import "components/agenda";
@import "components/block-head";
@import "components/shop-calendar";
@import "components/shop-schedule";
@import "components/paragraph";
@import "components/text";
@import "components/gallery-slider";
@import "components/sidebar";
@import "components/smartapp_banner";
@import "components/testimonial";
@import "components/boxed-grid";
@import "components/confirmation";
@import "components/social";
@import "components/tac";
@import "components/push-menu";
@import "components/hero-banner";
@import "components/toolbar";
@import "components/opening";
@import "components/video";

@import "pages/homepage";
@import "pages/shops";
@import "pages/movie";
@import "pages/news";
@import "pages/shop";
@import "pages/product";
@import "pages/restaurants";
@import "pages/restaurant";
@import "pages/contact";
@import "pages/generic_form";
@import "pages/boostcom";
@import "pages/access";
@import "pages/schedule";
@import "pages/single";
@import "pages/service";
@import "pages/vote";
@import "pages/auth";
@import "pages/culture-square-list";

@import "vendor/kids";
@import "vendor/covid";
