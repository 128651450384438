/*
 * SQUARE LIST
 */
.culture-square-list {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    justify-content: space-evenly;

    li {
        padding: 1rem;
    }

    a {
        text-decoration: none;
        color: #fff;
    }

    &__item {
        background: $c-primary-dark;
        color: #fff;
        width: 12rem;
        height: 12rem;
        font-size: 1.8rem;
        text-decoration: none;
        position: relative;
        text-align: center;
        transition: background 300ms;
        display: flex;
        justify-content: center;
        flex-direction: column;

        &::before {
            content: '';
            display: block;
            width: 12.8rem;
            height: 12.8rem;
            border: 1px solid $c-primary-dark;
            position: absolute;
            top: -.4rem;
            left: -.4rem;
        }

        figure {
            > i {
                font-size: 2em;
            }
        }

        svg, .culture-square-list__icon {
            height: 4rem;
            width: 4rem;
            display: block;
            margin: 0 auto 1rem;
        }

        &:hover, &:focus {
            background: $c-primary;
        }
    }

    &--over-header {
        @include mq($from: wide) {
            padding: 3rem 0;
            margin: 0;
            position: relative;
            z-index: 1;
            margin-top: -18rem !important;
            background: rgba(255, 255, 255, 0.7);

            li {
                padding: 2rem;
            }
        }
    }

    @include mq($until: wide) {
        padding: 2.7rem 0;
        margin-bottom: 0;

        &__item {
            margin: 1rem;
        }
    }
}
