/*------------------------------------*\
    #FOOTER
\*------------------------------------*/
.footer {
    padding-top: 1.5rem;
    position: relative;

    section {
        padding-top: 0;
    }

    h2 {
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1.5em;
        margin-top: 4rem;
    }

    .base-nav__menu {
        @extend %unstyled-list;
        font-size: 1.5rem;

        li {
            margin: .25em 0;
            vertical-align: top; 

            &:nth-child(odd) {
                margin-right: .75em;
            }
        }

        a {
            color: $c-medgray;
            text-decoration: none;
            display: block;
            line-height: 1.25;

            &:hover,
            &:focus,
            &:active {
                color: $c-txt;
            }
        }
    }

    &__anchor {
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        right: 2rem;
        top: -2rem;
        transition: background-color ease-in .25s;

        &:hover,
        &:active {
            background-color: $c-lightergray;

            .footer__anchor-content {
                &:after {
                    border-bottom-color: $c-lightergray;
                }
            }
        }

    }

    &__anchor-content {
        position: relative;

        &:after,
        &:before {
            content: '';
            position: absolute;
            left: 1rem;
            transition: border-color ease-in .25s;
        }

        &:before {
            border-left: 1rem solid transparent;
            border-right: 1rem solid transparent;
            border-bottom: 1.2rem solid $c-lightergray;
            top: 13px;
        }

        &:after {
            border-left: 1rem solid transparent;
            border-right: 1rem solid transparent;
            border-bottom: 1.2rem solid transparent;
            top: 17px;
        }
    }
}



/**
* Address
*/
.footer-address {
    margin: 0;
    font-style: normal;
    color: $c-medgray;
    font-size: 1.4rem;
    max-width: 20rem;

    &__title {
        font-size: 2.2rem;
        color: $c-txt;
        text-transform: uppercase;
        margin-top: 3.5rem;
        display: block;
    }

    &__route {
        font-size: 1.4rem;
        display: block;
        color: $c-txt;
        border: 1px solid $c-txt;
        text-decoration: none;
        text-transform: uppercase;
        padding: .25em 1em .375em;
        text-align: center;
        transition: background-color 250ms ease-in, color 250ms ease-in;

        &:hover,
        &:focus,
        &:active {
            background-color: $c-txt;
            color: #fff;
        }
    }
}





/**
* Newsletter
*/
.footer-newsletter {
    position: relative;
    font-size: 1.4rem;

    [type="email"] {
        border: 1px solid $c-medgray;
        width: 100%;
        background-color: transparent;
        height: auto;
    }

    [type="submit"] {
        position: absolute;
        bottom: 0;
        right: 0;
        border: 0;
        height: 4.3rem;
        border-left: 1px solid $c-medgray;
        background-color: transparent;
        transition: background-color 250ms ease-in, color 250ms ease-in;

        &:hover,
        &:focus,
        &:active {
            background-color: $c-darkgray;
            color: #fff;
        }
    }
}
