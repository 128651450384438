/*------------------------------------*\
    #QUICK INFOS
    Structure :
    - Quick links
    - Opening hours of the day
    - How to visit the center
\*------------------------------------*/

.quick-infos {
    @include flexbox();
    text-align: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    
    &__hours {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.3rem;
        padding: 1em 1.5em;
        color: #fff;
        width: 50%;
        @include flexbox();
        align-items: center;
        justify-content: center;
        transition: all 250ms ease-in;
        
        a {
            color: #fff;
            text-decoration: none;
        }
    }
    
    &__visit-us {
        color: $c-lightgray;
        width: 50%;
        flex: 1 1 50%;
        transition: all 250ms ease-in;

        a {
            color: $c-lightgray;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 1em;
            height: 100%;
            transition: background 250ms ease-in;
        }
        
        .icon-woman-silhouette {
            width: 3.2rem;
            height: 3.2rem;
        }
        
        .icon-right-long-arrow {
            margin: 0 .25em;
        }
        
        .icon-pin {
            width: 2.7rem;
            height: 2.7rem;
        }
        
        span {
            display: block;
            margin-top: 1em;
            flex: 1 0 100%;
        }
    }
    
    /* In footer */
    .footer & {
        clear: left;
        margin-top: 5rem;
        margin-left: $grid-gutter-width * -1em;
        margin-right: $grid-gutter-width * -1em;
    }
    
    @include mq($from: desktop) {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        
        &__hours {
            width: 20rem;
        }
        
        /* After hero banner */
        .hero-banner + &, .hbanner + & {
            -webkit-transform: translateY(-100%);
            -ms-transform: translateY(-100%);
            transform: translateY(-100%);
            position: absolute;
            left: 0;
            right: 0;
            max-width: $container-mw;
            width: 100%;
            margin: 0 auto;
            z-index: 5;
        }
    }
}

.quick-nav {
    -webkit-flex: 0 1 100%;
    flex: 0 1 100%;
    width: 100%;
    
    &__menu {
        list-style-type: none;
        @include flexbox();
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
        background-color: rgba(#fff, .8);
        margin-bottom: 0;
        padding-left: 0;
        height: 100%;
        
        li {
            flex: 1 1 0;
        }
        
        a {
            color: $c-txt;
            display: block;
            padding: 1em .5em;
            text-decoration: none;
            height: 100%;
            font-size: .8em;
            transition: all 250ms ease-in;
        }
        
        svg {
            width: 5rem;
            height: 5rem;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
        }
    }
    
    @include mq($from: tablet) {
        &__menu {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            justify-content: space-between;
            
            li {
                width: auto;
            }
            
            a {
                font-size: 1em;
                padding: 1.25em 1em;
            }
        }
    }
    
    @include mq($from: wide) {
        &__menu {
            padding: 0 1em;
        }
    }
}
