.shop-schedule {
    font-size: 2rem;
    text-align: center;
    color: #fff;

    &__icon {
        position:relative;
        margin: 0 0 1rem 0;
        padding: 0;
        width: 4.6rem;
        height: 4.6rem;

        /*
         Is this really useful ???

        &:before {
            position: absolute;
            content: '';
            top: -8px;
            left: calc(50% - 23px);
            width: 46px;
            height: 46px;
            border: 5px solid #1e629a;
            border: 5px solid $c-primary;
            border-radius: 50%;
        }

        &:after {
            position: absolute;
            content: '';
            top: -2px;
            left: calc(50% - 17px);
            width: 34px;
            height: 34px;
            border: 1px solid #fff;
            border-radius: 50%;
        }*/
    }

    &__title {
        font-weight: bold;
    }

    &__body {

    }

    @include mq($from: tablet) {
        .-kids & {
            &:after {
                content: '';
                position: absolute;
                left: -65px;
                bottom: -65px;
                background: url('../../images/kids/balloons_2.png') no-repeat;
                width: 120px;
                height: 120px;
                z-index: 2;
            }

            &:before {
                content: '';
                position: absolute;
                right: -13px;
                top: 90%;
                background: url('../../images/kids/balloons_5.png') no-repeat;
                width: 55px;
                height: 55px;
                z-index: 2;
            }
        }
    }
}
