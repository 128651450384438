/*------------------------------------*\
    #POP CARD
    Structure :
    - Image
    - Content :
        - Title
        - Summary
        - Call to Action

    Content is over the image.
\*------------------------------------*/

.pop-card {
    background-color: #fff;
    text-align: center;
    border: 1px solid transparent;
    position: relative;

    /* Image */
    &__media {
        margin-bottom: -9.5rem;

        img {
            width: 100%;
            height: auto;
        }
    }

    /* Content */
    &__content {
        margin: 2rem 2rem;
        border: 1px solid $c-lightgray;
        background-color: #fff;
        padding: 3rem 2rem;
        position: relative;
    }

    &__title {
        font-size: 1.6rem;
        min-height: 2em;
        text-transform: uppercase;

        a {
            color: $c-txt;
            text-decoration: none;
        }
    }

    &__summary {
        font-family: $ff-sans-serif;
        font-size: 1.3rem;
        margin: 1.5em 0 0;
    }
}

a .pop-card {
    &__title, &__summary {
        color: $c-txt;
    }
}