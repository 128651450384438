/*------------------------------------*\
    #NAVIGATION
\*------------------------------------*/

/**
* MAIN NAVIGATION
*/
.main-nav {
    &__menu {
        @extend %unstyled-list;
        font-size: 1.6rem;

        a {
            text-decoration: none;
            font-weight: 500;
            padding: .5rem 0;
            position: relative;
            transition: color 250ms ease-in;

            @include mq($from: desktop) {
                text-transform: uppercase;
            }
        }
        .-vip a {
            font-weight: bold;
            text-transform: none;
        }

        .-highlight a {
            font-weight: bold;
        }
    }

    @include mq($until: desktop) {
        text-align: center;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: $c-darkergray;
        z-index: 150;
        padding: 1.5em 0;
        visibility: hidden;

        &[aria-hidden="false"] {
            visibility: visible;
            overflow-y: scroll;
            max-height: calc(100vh - 9rem);
        }

        .no-js & {
            position: relative;
        }

        &__menu {
            li {
                &:not(:first-child) {
                    margin-top: 1.5em;
                }
            }
        }

    }

    @include mq($from: desktop) {
        margin-top: 1rem;
        text-align: right;
        position: relative;
        vertical-align: bottom;

        &__menu {
            display: inline-block;

            li {
                display: inline-block;
                vertical-align: middle;

                + li {
                    margin-left: 1rem;
                    position: relative;

                    &:before {
                        content: '·';
                        display: inline-block;
                        margin-right: 1rem;
                    }
                }

                &.current {
                    a {
                        border-bottom: 1px solid currentColor;
                    }
                }
            }

            a {
                &:hover,
                &:focus,
                &:active {
                    border-bottom: 1px solid currentColor;
                }
            }
        }
    }
}

/* Menu Trigger = Hamburger*/
.main-nav-burger {
    background: transparent;
    border: 0;
    padding: 0;
    margin-top: -.4rem;
    right: 1rem;
    @include centerer(false, true);
    z-index: 50;

    &__burger {
        background-color: #fff;
        display: inline-block;
        vertical-align: middle;
        height: 0.2rem;
        margin: 0.5rem auto;
        position: relative;
        text-indent: -999em;
        transition: background-color 250ms ease-in-out 0s;
        width: 1.6rem;

        &:before,
        &:after {
            background-color: #fff;
            content: "";
            display: block;
            height: 0.2rem;
            position: absolute;
            top: -0.5rem;
            transition: top 250ms ease-in-out, transform 250ms ease-in-out;
            width: 100%;
        }

        &:after {
            top: 0.5rem;
        }

        [aria-expanded="true"] & {
            background-color: transparent;

            &:before {
                top: 0;
                -webkit-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            &:after {
                top: 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    &__txt {
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        margin-right: .5rem;
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: bold;
    }

    @include mq($from: desktop) {
        display: none;
    }
}

/**
* SUPPORT MENU
*/
.support-menu {
    @extend %unstyled-list;
    font-size: 1.4rem;

    a {
        color: $c-medgray;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }

    .current {
        a {
            text-decoration: none;
            font-weight: bold;
        }
    }

    @include mq($until: desktop) {
        margin-top: 2rem;
        padding-top: 1.5rem;
        border-top: 1px solid $c-darkgray;

        li {
            padding: .5rem 0;
        }
    }

    @include mq($from: desktop) {
        position: absolute;
        bottom: 100%;
        margin-bottom: 3rem;
        right: 0;
        line-height: 1.1;

        &-searchable {
            right: 4rem;
        }

        li {
            display: inline-block;
            vertical-align: middle;

            &:not(:first-child) {
                border-left: 1px solid rgba($c-medgray, .5);
                padding-left: 2rem;
                margin-left: calc(2rem - .25em);
            }

            &.last + li {
                padding-left: .5rem;
            }
        }
    }
}

body.has-main-menu {
    overflow: hidden;
    position: relative;

    &:after {
        content: '';
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        top: 9rem;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 101;
        // hover slider
    }
}

.pagination {
    max-width: 56rem;
    margin: 0 auto;
    text-align: center;
    padding-top: 2rem;
    border-top: 1px solid $c-lightgray;

    &__item {
        display: inline-block;
        padding: 1rem;
        color: #000;
        line-height: 1;
        font-style: italic;
        text-decoration: none;
        transition: background-color 250ms ease-in, color 250ms ease-in, border-color 250ms ease-in;

        &.active {
            color: #fff;
        }
    }

    a.pagination__item {
        &:hover,
        &:active {
            color: #fff;
        }
    }

    &__first,
    &__prev,
    &__next,
    &__last {
        display: inline-block;
        padding: 1rem;
        color: #000;
        line-height: 1;
        transition: background-color 250ms ease-in, color 250ms ease-in, border-color 250ms ease-in;
        text-decoration: none;

        svg {
            width: 1.3rem;
            height: 1.3rem;
        }

        &:hover,
        &:active {
            color: #fff;
        }
    }

    a + a {
        margin-left: 1rem;
    }
}
