/*------------------------------------*\
    #HEADER
\*------------------------------------*/
$header-a-height-xl: 12.5rem;
$header-a-height-lg: 10rem;

.header__logo {
    width: 100%;
    max-width: 15rem;
    display: inline-block;
    vertical-align: middle;
}

.header__logo img {
    max-height: 6rem;
    width: auto;
}

.header {
    padding: 2rem 0 2rem;
    position: relative;

    &__layout_a {
        &.has-main-menu {
            &:after {
                top: $header-a-height-xl;
            }
        }

        .header {
            height: $header-a-height-xl;

            & > .container-fluid {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                align-content: center;
                height: 100%;
            }

            &__logo {
                max-width: 14rem;
                margin-right: auto;

                img {
                    max-height: 6rem;
                }
            }
        }

        .main {
            margin-top: $header-a-height-xl;
        }

        .main-nav {
            margin-top: 3.5rem;
        }

        .support-menu {
            margin-bottom: 2rem;
        }
    }

    @include mq($from: desktop) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;// because of autocomplete
        min-height: 10rem;

        &__logo {
            max-width: 20rem;
        }
    }

    @include mq($from: wide) {
        &__logo {
            max-width: 25rem;
        }
    }

    @include mq($until: wide) {
        &__layout_a {
            &.has-main-menu, &.has-search {
                &:after {
                    top: $header-a-height-lg;
                }
            }

            .header {
                height: $header-a-height-lg;

                &__logo {
                    img {
                        max-height: 5rem;
                    }
                }
            }

            .main {
                margin-top: $header-a-height-lg;
            }

            .main-nav {
                margin-top: 0;

                &[aria-hidden="false"] {
                    max-height: calc(100vh - #{$header-a-height-lg});
                }
            }
        }
    }

    @include mq($until: desktop) {
        &__layout_a {
            .main {
                margin-top: 0;
            }
        }

        &__layout_b {
            .search-form {
                &__trigger {
                    top: -5.4rem;
                }
            }
        }
    }

    @include mq($until: tablet) {
        &__layout_a {
            .header {
                &__logo {
                    img {
                        max-height: 4rem;
                    }
                }
            }
        }
    }
}

/**
 * Handle the modern dynamic design transformer
 */
@media screen and (min-width: 62em) {
    body.modern_dynamic.front:not(.has-search) {
        transition: all ease-in-out 150ms;

        &.has-search:after {
            top: 0;
        }

        &.modern_dynamic__enabled {
            header.header {
                background-color: transparent;
                transition: all ease-in-out 150ms;

                .header__logo {
                    img {
                        filter: brightness(0) invert(1);
                        transition: all ease-in-out 150ms;
                    }
                }

                .main-nav {
                    background-color: transparent;
                    transition: all ease-in-out 150ms;

                    &__menu {
                        color: #fff;
                        transition: all ease-in-out 150ms;
                    }

                    .search-form__trigger svg {
                        fill: #fff;
                        transition: all ease-in-out 150ms;
                    }

                    .support-menu a {
                        color: #fff;
                        transition: all ease-in-out 150ms;
                    }
                }
            }
        }

        .hbanner.fullpage {
            height: 100vh;
        }

        main.main {
            margin-top: 0;
        }
    }
}
