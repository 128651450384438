
.boostcom-form {
    label {
        font-weight: bold;
        text-transform: none;
        display: block;
    }

    &.form-horizontal [type="checkbox"] + label {
        text-transform: none;
    }

    .yesno-radio {
        label {
            display: inline-block;
            font-size: 1.4rem;
            border: 1px solid $c-txt;
            background-color: transparent;
            color: $c-txt;
            font-weight: bold;
            vertical-align: middle;
            text-align: center;
            padding: 0.75em 2em;
            margin: 1rem 0;
            line-height: 1;
            text-decoration: none;
            transition: background-color 250ms ease-in, color 250ms ease-in;

            &:hover, &:active {
                background-color: $c-txt;
                color: #fff;
            }
        }

        input {
            display: none;

            &:checked + label {
                color: #fff;
            }
        }
    }
}
