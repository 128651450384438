/*------------------------------------*\
    #RESTAURANTS
\*------------------------------------*/
.restaurants-search {
    text-align: center;
    margin: 4rem 0;

    label {
        text-transform: uppercase;
        font-weight: 900;
    }

    select {
        margin-left: 1em;
        margin-right: 1em;
        min-width: 15rem;
    }

    @include mq($until: tablet){
        label {
            display: block;
            margin: 1em 0;
        }
    }
}



/* Listing */
.listing[id="restaurants-list"] {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    > li {
        float: none;

        &[aria-hidden="true"] {
            display: none;
        }
    }


    @include mq($from: tablet){
        > li {
            width: 49.5%;
        }
    }


    @include mq($from: desktop){
        > li {
            width: 24.5%;
        }
    }
}