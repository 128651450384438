.social {
    &--inline {
        @extend .inline-list;
    }

    a {
        transition: color 150ms ease;
        color: $c-medgray;

        &:hover,
        &:focus,
        &:active {
            color: $c-txt;
        }
    }
}

.aside {
    .social {
        text-align: center;
        font-size: .8em;
        padding-top: 1em;
    }
}
