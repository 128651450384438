/*------------------------------------*\
    #VISIT US BLOCK
\*------------------------------------*/

.visit-us-block {
    display: block;
    text-align: center;
    padding: 2.5em;
    max-width: 63rem;
    margin: 2em auto;
    color: $c-lightgray;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-decoration: none;
    font-weight: bold;
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid #fff;
        margin: 1rem;
        transition: border 200ms ease-in, margin 200ms ease-in;
    }

    .icon-woman-silhouette {
        width: 3.2rem;
        height: 3.2rem;
    }

    .icon-right-long-arrow {
        margin: 0 .25em;
    }

    .icon-pin {
        width: 2.7rem;
        height: 2.7rem;
    }

    span {
        display: block;
        margin-top: 1.5em;
        color: #fff;
    }


    &:hover,
    &:focus,
    &:active {
        &::after {
            border-width: 1rem;
            margin: .1rem;
        }
    }
}
