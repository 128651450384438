/*------------------------------------*\
    #NEWS PAGES
    Listing and details
\*------------------------------------*/

/**
* LISTING
*/
.listing.news-list {
    @include flexbox();
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    
    > li {
        &[aria-hidden="true"] {
            display: none;
        }
    }

    > div {
        &[aria-hidden="true"] {
            display: none;
        }
    }
        
    @include mq($until: tablet) {
        > li {
            width: 50%;
        }
    }

    @include mq($until: mobile) {
        > li {
            width: 100%;
        }
    }
}

/**
* EDITO
*/
.news-edito {
    background-color: #fff;
    position: relative;
    @include flexbox();
    padding: 4em 2em;
    margin-bottom: 0.625em;
    
    &::before {
        content: '';
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px solid currentColor;
        position: absolute;
        margin: 1em;
    }
    
    &__content {
        text-align: center;
        margin: auto;
    }
    
    &__title {
        font-family: $ff-serif;
        font-size: 2.8rem;
        margin-bottom: 1.5em;
        text-transform: uppercase;
    }
    
    &__body {
        font-size: 1.8rem;
    }
}

/**
*   NEWS DETAILS
*/
.news-details {
    &--transition {
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
    }

    &__media {
        position: relative;
        margin-bottom: .625em;
    }
    &__video {
        margin-top: .925em;
    }
    
    /* Sharing */
    .sharing-toggle {
        position: absolute;
        top: 0;
        right: 0;
    }
    
    &__content {
        padding: 3em;
        background-color: #fff;
        margin-top: 2rem;
        
        p,
        ul,
        ol {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    
    &__body {
        font-size: 1.5rem;

        /**
         * Hack to display a video from Youtube in a responsive way

         * @see https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
         */
        .video-responsive {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;

            .video, iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    
    &__title {
        font-size: 2rem;
        margin-bottom: 1.5em;
        text-transform: uppercase;
        font-weight: 900;
    }

    &__date {
        display: block;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: bold;
        color: $c-medgray;
        margin: 2rem 0;
    }

    &__blog {
        .news-details {
            &__content {
                padding: 1em;
                line-height: 1.5em;
                letter-spacing: .01em;
            }

            &__subdetails {
                margin-top: 4em;
                position: relative;

                &:before {
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    background-image: linear-gradient(to right,#bbc4c7 40%,rgba(255,255,255,0) 0%);
                    background-position: top;
                    background-size: 7px 1px;
                    background-repeat: repeat-x;
                    margin-top: -1em;
                }

                &:after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    background-image: linear-gradient(to right,#bbc4c7 40%,rgba(255,255,255,0) 0%);
                    background-position: top;
                    background-size: 7px 1px;
                    background-repeat: repeat-x;
                    bottom: -1em;
                }

                .hashtags {
                    float: left;
                    width: 75%;

                    span {
                        padding: .5em .7em;
                        letter-spacing: .1rem;
                        transition: opacity 125ms ease-in-out;
                    }

                    a {
                        text-decoration: none;
                        @extend .news-details--transition;
                    }
                }

                .sharer {
                    float: right;
                    text-align: right;
                    width: 25%;

                    &__item {
                        a {
                            width: 25px;
                            display: inline-block;
                            text-align: center;
                            color: $c-medgray;
                            text-decoration: none;
                            @extend .news-details--transition;

                            &:hover,
                            &:active,
                            &:focus {
                                color: darken($c-medgray, 25%);
                            }
                        }
                    }
                }

                @include mq($until: tablet) {
                    .sharer, .hashtags {
                        width: 100%;
                        float: none;
                        text-align: center;
                    }

                    .hashtags + .sharer {
                        margin-top: 1.5rem;
                    }
                }
            }
        }
    }

    @include mq($until: mobile) {
        &__body {
            h1, h2, h3, h4 {
                clear: both;
            }
        }
    }
}
