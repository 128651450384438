/* ==========================================================================
	 Form basic style
	thanks to HTML5boilerplate,
	github.com/nathansmith/formalize
	and www.sitepen.com
	 ========================================================================== */

/*
* forms items
*/
button {
	-webkit-appearance: none;
}
input,
button,
select,
label,
[class*="btn-"] {
	vertical-align: middle;
	font-family: inherit;
	font-size: inherit;
}

label {
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
}

fieldset {
    border: 0;
}

legend {
	border: 0;
	white-space: normal;
    display: inline-block;
    text-align: center;
    margin: 1.5rem 0;

    fieldset:first-child & {
        margin-top: 0;
    }
}

textarea {
	vertical-align: top;
	font-family: inherit;
	font-size: inherit;
	resize: vertical;
}

/*
* Clickable input types in iOS
*/
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	cursor: pointer;
	-webkit-appearance: button;
}

input[type="search"] {
	-webkit-appearance: textfield;
}


/*
* Autocomplete field on webkit
*/
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    //box-shadow: 0 0 0 1000px white inset !important;
    //color: #fff !important;
}

.autofillColored {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-transition: background-color 9999s ease-out;
        -webkit-transition-delay: 9999s;
        -webkit-text-fill-color: #fff !important;
        border: 1px solid #fff;
    }
}

.-colored-primary {
    @extend .autofillColored;
}


/* if select styling bugs on WebKit
* 'x' appears on right of search input when text is entered. This removes it
*/
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

/*
* Removes inner padding and border in FF3+
*/
button::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner {
	border: 0;
	padding: 0;
}

/*
* Placeholder
*/
::-webkit-input-placeholder {
	color: #777;
	opacity: 1;
}

input:-moz-placeholder,
input::-moz-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder {
	color: #777;
	opacity: 1;
}


/**
* Input style
*/
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
textarea,
select {
    background-color: $input-bg;
    border: $input-border;
    padding: $input-padding;
    height: 4.3rem;
    border-radius: 0;
    box-shadow: none;


    .form-field.has-error & {
        color: map-get($message-types, error);
        border-color: map-get($message-types, error);
    }
}

textarea {
    height: auto;
    padding: 1rem 1.5rem;
}


/* Select */
select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAAAdUlEQVQokY3QWxHCQAyF4Q8JawELa6FaaqEWsFALaEBCtWBheSCdZphOyf+Uze3k7A0dzZeXOnlOxxsDz1y4YIn+gXlP3rFFcov3GS2ERghP/xr6T70qBB5p0X7q5LC8qlk2OzyvKV4qw5n84af+q7RQv/T/AWLMIZ3Vzy83AAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: right 15px top 50%;
    padding-right: 4.5rem;
}
select::-ms-expand {
    display: none;
}
option {
    padding-top: .25em;
    padding-bottom: .25em;

    &:not(:first-child) {
        border-top: 1px solid currentColor;
    }
}



/* Form item */
.form-field {
    @extend %clearfix;
    position: relative;
}


[type="radio"] {
    margin-right:0.3rem;
}

/* Checkbox */
[type="checkbox"] {
    position: absolute;
    opacity: 0;

    /*&:focus {
        + label {
            &::after {
                opacity: .25;
            }
        }
    }*/

    + label {
        padding-left: 1.5em;
        display: inline-block;
        cursor: pointer;
        position: relative;

        &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 1.4rem;
            height: 1.4rem;
            border: .1rem solid $c-medgray;
            position: absolute;
            top: .3em;
            left: 0;
        }

        &::after {
            content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTNweCIgaGVpZ2h0PSIxM3B4IiB2aWV3Qm94PSIwIDAgMTMgMTMiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEzIDEzOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6IzEyMTMxMzt9LnN0MXtkaXNwbGF5Om5vbmU7ZmlsbDojMTIxMzEzO308L3N0eWxlPjxwYXRoIGlkPSJDaGVjayIgY2xhc3M9InN0MCIgZD0iTTExLjQsMy4xYy0wLjItMC4yLTAuNC0wLjItMC42LDBMNC44LDlMMi4yLDYuNGMtMC4yLTAuMi0wLjQtMC4yLTAuNiwwUzEuNSw2LjksMS42LDdsMi45LDIuOWMwLjIsMC4yLDAuNCwwLjIsMC42LDBsNi4yLTYuMkMxMS41LDMuNSwxMS41LDMuMywxMS40LDMuMUMxMS4yLDIuOSwxMS41LDMuMywxMS40LDMuMXoiLz48cmVjdCB4PSIwIiB5PSIwIiBjbGFzcz0ic3QxIiB3aWR0aD0iMTMiIGhlaWdodD0iMTMiLz48L3N2Zz4=');
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            left: .1rem;
            top: .1em;
            transition: opacity 250ms ease-in;
            opacity: 0;
        }

        &:hover,
        &:focus {
            &::after {
                opacity: .25;
            }
        }
    }

    &:checked {
        + label {
            &::before {
                border-color: $c-darkgray;
            }
            &::after {
                opacity: 1;
            }
        }
    }
}


/***
* RATING STARS
*/
.form-rating {
    float: left;
    color: #FDB600;
    font-size: 2em;
    line-height: 1;

    label {
        float: right;

        &:before {
            content: '☆ ';
        }

        &:hover {
            ~ label:before,
            &:before {
                content: '★ ';
            }
        }
    }

    [type="radio"] {
        @extend %sr-only;

        &:checked {
            ~ label {
                &:before {
                    content: '★ ';
                }
            }
        }
    }
}



/**
* FORM HORIZONTAL
*/
.form-horizontal {
    .form-field {
        @extend %clearfix;
        margin: 1rem 0;
    }
    .form-action {
        margin-top: 2em;
    }

    [type="text"],
    [type="email"],
    [type="password"],
    [type="tel"],
    [type="url"],
    textarea {
        width: 100%;
    }

    [type="checkbox"] {
        + label {
            font-size: 1.4rem;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    input:-moz-placeholder,
    input::-moz-placeholder,
    textarea:-moz-placeholder,
    textarea::-moz-placeholder {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $c-medgray;
    }

    ::-webkit-input-placeholder {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $c-medgray;
    }
}

.label-sr-only {
    label {
        @extend %sr-only;
    }
}


.select2-container, .select2-selection {
    border-radius: 0!important;
    margin-left: 0!important;
    min-height: 4.3rem!important;
    border-color: #999!important;
}

.select2-selection {
    padding-left: 1rem;
}

.select2-container .select2-container--open {
    border-radius: 2px!important;
    outline: 2px solid;
}

.select2-selection--multiple:before {
    content: "";
    position: absolute;
    right: 7px;
    top: 42%;
    border-top: 5px solid #888;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}