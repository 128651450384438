/*
 * HEADER
 */
.culture-header {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 1rem;
    margin-top: -1rem;

    &__map {
        display: none;
    }

    &__img {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 50%;
        z-index: -1;
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        transform: translate(-50%, -50%);
    }

    &__container {
        text-align: center;
        margin: 0 auto;
        padding: 6rem $grid-gutter-width * 2em;
    }

    &__title {
        font-family: $ff-serif;
        font-size: 5rem;
        font-weight: 400;

        .-culture & {
            color: #fff;
        }
    }

    &__subtitle {
        font-size: 2rem;
        font-weight: normal;
        margin-top: -2rem;
    }

    &__text {
        font-size: 1.8rem;
        max-width: 62rem;
        margin: 0 auto;

        .-culture & {
            color: #fff;
        }
    }

    @include mq($from: desktop) {
        &__container {
            padding-top: 8rem;
            padding-bottom: 8rem;
        }
    }

    @include mq($from: wide) {
        &__map {
            display: block;
            position: absolute;
            top: -5rem;
            left: -40rem;
            height: 50rem;
        }

        &__container {
            padding-top: 9.3rem;
            padding-bottom: 5rem;
        }

        &__title {
            font-size: 6rem;
        }

        // modifier
        &--with-square-list {
            .culture-header__container {
                padding-top: 18rem;
                padding-bottom: 29rem;
            }
        }
    }

    @include mq($from: xwide) {
        &__map {
            top: -7rem;
            left: -50rem;
            height: 60rem;
        }
    }
}
