/**
 * CONTENTS
 *
 * VARIABLES
 * Colors........... All website colors
 * Themes........... Themes for clinic
 * Typography....... Font-family, font-size, line-height
 * Buttons.......... Buttons informations
 * Form............. Input, select style
 * Media Queries ... Breakpoint for responsive
 * Grid ............ Grid col and gutter
 */



/*------------------------------------*\
    #COLORS
\*------------------------------------*/
$c-primary-dark:    #4caec7;
$c-primary:         #8ac5d7;
$c-secondary:       #f9a69d;
$c-secondary-light: lighten($c-secondary, 10%);
$c-offers:          #f63e05;
$c-vip:             #bd9431;

/* Kids */
$c-kids:            #d3e7f6;
$c-kids-secondary:  #f1f6f9;
$c-kids-tertiary:   #f67fbc;
$c-kids-alt:        #bcedf7;

$c-calendar: (
    #f696b8,
    #f7c3b0,
    #69deab,
    #c8d04b,
    #175388,
);


/* Gray and others */
$c-lightergray:     #f3f3f3;
$c-lightgray:       #e8e8e8;
$c-medgray:         #999;
$c-darkgray:        #383838;
$c-darkergray:      #1a1a1a;

/* Text color */
$c-txt:             #1e1e1e;


/* Links colors */
$c-link:            $c-primary;
$c-hover:           $c-primary;
$c-visited:         darken($c-link, 10%);


/* Notifications colors */
$message-types: (
    error:      #dd7070,
    warn:       #f0ad4e,
    success:    #318026,
    info:       #5bc0de,
    help:       $c-medgray,
);




/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
$fz-base: 1.4em;
$ff-sans-serif: "Source Sans Pro", Arial, Helvetica, sans-serif;
$ff-serif: Playfair, Georgia, "Times New Roman", serif;
$ff-heading: "Source Sans Pro", Impact, sans-serif;


/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
$btn-radius:      0;
$btn-padding:     1.5rem 3rem;
$btn-border:      0;
$btn-minwidth:    18.5rem;



/*------------------------------------*\
    #FORMS
\*------------------------------------*/
$input-bg:          #fff;
$input-radius:      0;
$input-padding:     .3rem 1.5rem .2rem;
$input-border:      1px solid $c-medgray;




/*------------------------------------*\
    #MEDIA-QUERIES
\*------------------------------------*/
// Extra small screen / phone
$screen-xs:                  30em !default;
$screen-xs-min:              $screen-xs !default;

// Small screen / tablet
$screen-sm:                  48em !default;
$screen-sm-min:              $screen-sm !default;

// Medium screen / desktop
$screen-md:                  62em !default;
$screen-md-min:              $screen-md !default;

// Large screen / wide desktop
$screen-lg:                  75em !default;
$screen-lg-min:              $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;





/*------------------------------------*\
    #MEDIA QUERIES
\*------------------------------------*/
// To enable support for browsers that do not support @media queries,
// (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
// Create a separate stylesheet served exclusively to these browsers,
// meaning @media queries will be rasterized, relying on the cascade itself
$mq-responsive: true;

$mq-base-font-size: $fz-base;

// Name your breakpoints in a way that creates a ubiquitous language
// across team members. It will improve communication between
// stakeholders, designers, developers, and testers.
$mq-breakpoints: (
    mobile:     $screen-xs,
    tablet:     $screen-sm,
    desktop:    $screen-md,
    wide:       $screen-lg,
    xwide:      87.5em,

    // Tweakpoints
    phablet: 37.5em,
    mobileLandscape: 30em
);

// Define the breakpoint from the $mq-breakpoints list that should
// be used as the target width when outputting a static stylesheet
// (i.e. when $mq-responsive is set to 'false').
$mq-static-breakpoint: desktop;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width, e.g. (mobile, tablet, desktop).
$mq-show-breakpoints: ();



/*------------------------------------*\
    #GRID
\*------------------------------------*/

//** Number of columns in the grid.
$grid-columns:              12 !default;
$grid-gutter-width:         (10 / ($fz-base * 10)) * 1em !default;


//== Container sizes
// Small screen / tablet
$container-tablet:             (45em + $grid-gutter-width) !default;
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (58.75em + $grid-gutter-width) !default;
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (71.25em + $grid-gutter-width) !default;
$container-lg:                 $container-large-desktop !default;

// Container max-width
$container-mw:                 110rem;
$container-sm-mw:              100rem;

/*------------------------------------*\
    #Font Awesome
\*------------------------------------*/
$fa-font-path:        "~@fortawesome/fontawesome-pro/webfonts";
