/*------------------------------------*\
    #SHOPS
    Listing of all shop
\*------------------------------------*/

/* Listing of shop categories */
.shop-categories-list {
  position: relative;
}

/* Listing of shops logo */

.underpanel {
  padding: 10rem 0;
  position: absolute;
  left: $grid-gutter-width * 1em;
  right: $grid-gutter-width * 1em;
  opacity: 0;
  z-index: -5;
  transition: max-height 250ms ease-in, opacity 250ms ease-in, padding 250ms ease-in;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 99em;
    background-color: inherit;
  }

  &::before {
    right: 100%;
  }

  &::after {
    left: 100%;
  }

  &__close {
    position: absolute;
    top: 2rem;
    right: 0;
    color: #fff;
    background-color: transparent;
    border: 0;
    padding: 0;
  }
}

/* Logo square */
.logo-square {
  display: block;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  transition: opacity 250ms ease-in, box-shadow 350ms ease-in;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    @include centerer();
    padding: 2rem;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: .75;
    box-shadow: 0 3px 6px rgba(#000, .15);
  }

  /* SALES */
  &__sales {
    display: block;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.5rem;
    transform: translate(-50%, -50%) rotate(-45deg) ;
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    line-height: 1.2;

    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    > span {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
