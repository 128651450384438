/*------------------------------------*\
    #RESTAURANT
\*------------------------------------*/

/* BANNER */
.restaurant-banner {
    position: relative;
    overflow: hidden;
    z-index: 4;


    &__img {
        @include cover-image;
        z-index: -1;
    }


    @include mq($from: tablet){
        height: 75rem;
    }


    @include mq($from: wide){
        height: 100rem;
    }
}



/* INFORMATIONS */
.restaurant-details {
    padding: 2em;
    background-color: rgba(#fff, .5);
    width: 100%;
    max-width: 93rem;
    margin: 6rem auto 0;
    position: relative;
    z-index: 4;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid #fff;
        margin: 1rem;
        z-index: -1;
    }

    &__desc {
        text-align: center;
        font-family: $ff-serif;
        margin-bottom: 2em;
    }

    &__logo {
        img {
            max-width: 15rem;
            max-height: 12rem;
        }
    }

    &__infos {
        @extend %unstyled-list;

        li {
            min-height: 2.4rem;
            position: relative;
            padding-left: 4rem;

            + li {
                margin-top: 1.25em;
            }

            > svg {
                @include centerer(false, true);
                left: 0;
                fill: $c-darkgray;
            }
        }

        a {
            margin-left: 1.5em;
        }
    }


    @include mq($from: tablet) {
        position: absolute;
        bottom: 6rem;
        left: 50%;
        padding: 4em;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);

        &__desc {
            width: 60%;
            padding-right: 5em;
            margin-bottom: 0;
        }
    }
}


/* SERVICES */
.restaurant-services {
    @extend %unstyled-list;
    font-size: 1.5rem;
    font-family: $ff-serif;
    margin: 1em 0 0;

    li {
        padding: 1em 0;

        &:not(:first-child) {
            border-top: 1px solid $c-lightgray;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        border: 1px solid $c-txt;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        padding: .5rem;
        position: relative;

        &::before {
            content: '';
            width: calc(100% + 1.6rem);
            height: calc(100% + 1.6rem);
            border-radius: 50%;
            border: 1px solid #fff;
            position: absolute;
            top: -.8rem;
            left: -.8rem;
        }

        > svg {
            @include centerer();
        }
    }

    &__label {
        display: inline-block;
        margin-left: 1em;
        vertical-align: middle;
        width: calc(100% - 8rem);
    }

    @include mq($from: tablet){
        &__icon {
            width: 5rem;
            height: 5rem;
            line-height: 5rem;

            > svg {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
}
