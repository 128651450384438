/*------------------------------------*\
    #SCHEDULE / HORAIRES
\*------------------------------------*/

.hours-block {
    @include mq($from: tablet){
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}


.block_mall_schedule {
    &_dimension {
        &__full {
            flex: 0 0 auto;
            width: 100%;
        }
        &__half {
            flex: 0 0 auto;
            width: 100%;

            @include mq($from: wide){
                width: calc(50% - .5rem);
            }

        }
        //&__quarter {
        //    flex: 0 0 auto;
        //    width: 100%;
        //
        //    @include mq($from: tablet){
        //        width: calc(50% - .5rem);
        //    }
        //
        //    @include mq($from: wide){
        //        width: 25%;
        //    }
        //
        //}
    }

    &_color-scheme {
        &__default {

            background-color: #fff;
            position: relative;
            padding: 4rem;
            text-align: center;
            z-index: 1;

            &::before {
                content: '';
                margin: 1rem;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                border: 1px solid #000;
                z-index: -1;
            }
        }

        &__primary {
            color: #fff;
            text-align: center;
            padding: 5rem;
            margin-bottom: 1rem;

            .days {
                color: white;
            }

            .title {
                font-size: 2.2rem;
            }

            ::-webkit-input-placeholder {
                color: #fff;
            }

            input:-moz-placeholder,
            input::-moz-placeholder,
            textarea:-moz-placeholder,
            textarea::-moz-placeholder {
                color: #fff;
            }

            .btn-primary {
                color: #fff;
                border-color: #fff;
            }

            @include mq($from: desktop){
                padding: 9rem 5rem;
            }

        }

        &__dark,
        &__dark_secondary {
            display: block;
            color: #fff;
            padding: 2em;
            text-decoration: none;
            margin-bottom: 1rem;

            text-align: center;

            @include mq($from: desktop){
                padding: 4rem 2em;

                //.market-hours + & {
                //    margin-left: 1rem;
                //}
            }
            .days {
                color: white;
            }

            .btn-primary {
                color: #fff;
                border-color: #fff;
            }
        }

        &__light {
            background-color: #fff;
            padding: 2em;
            text-align: center;
            margin-bottom: 1rem;

            img {

            }

            .days {
                //color: #ff685c;
                margin-top: .75em;
                li {
                    margin: .25em 0;
                }
            }
        }
    }

}

.block_mall_schedule {

    .days {
        @extend %unstyled-list;
        font-size: 1.6rem;
        margin-top: 1rem;

        &:first-child {
            margin-top: 0;
        }

        li {
            margin: .25em 0;
        }
    }

    h4,
    h3,
    .title {
        font-size: 2.2rem;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 1.5em;
    }

    h4 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        text-transform: capitalize;
    }

}


/* Mall hours */
.main-hours,
.mall-hours {
    background-color: #fff;
    position: relative;
    padding: 4rem;
    text-align: center;
    z-index: 1;

    &::before {
        content: '';
        margin: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px solid #000;
        z-index: -1;
    }

    &__title {
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 1.5em;
    }

    &__days {
        @extend %unstyled-list;
        margin-top: 1.5em;

        &:first-child {
            margin-top: 0;
        }

        li {
            margin: .25em 0;
        }
    }


    @include mq($from: tablet){
        width: calc(50% - .5rem);
    }

    @include mq($from: wide){
        width: calc(43% - .5rem);
    }
}

.main-hours {
    > img,
    > div {
        margin-bottom: 4rem;
    }
}

/**
* OTHER HOURS
*/
.secondary-hours,
.other-hours {
    @include mq($from: tablet){
        width: calc(50% - .5rem);
    }

    @include mq($from: wide){
        width: calc(57% - .5rem);
    }
}

.secondary-hours {

    @include mq($from: tablet){
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

}


.market-restaurants-hours {
    @include mq($from: desktop){
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;

        > * {
            flex: 1 1 0;
        }
    }
}


/* Shops hours - search */
.search-shops-hours {
    color: #fff;
    text-align: center;
    padding: 5rem;

    &__title {
        font-size: 2.2rem;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 1.5em;
    }

    &__form {
        position: relative;
        max-width: 45rem;
        margin: 0 auto;


        [type="text"] {
            border: 2px solid #fff;
            background-color: transparent;
            height: auto;
            width: 100%;
            color: #fff;
        }

        [type="submit"] {
            background-color: transparent;
            border: 0;
            border-left: 2px solid #fff;
            height: 100%;
            color: #fff;
            transition: background-color 250ms ease-in, color 250ms ease-in;

            &:hover,
            &:focus,
            &:active {
                background-color: #fff;
                color: $c-txt;
            }
        }

        .form-action {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            font-size: 1.4rem;
            font-weight: bold;
        }
    }


    ::-webkit-input-placeholder {
        color: #fff;
    }

    input:-moz-placeholder,
    input::-moz-placeholder,
    textarea:-moz-placeholder,
    textarea::-moz-placeholder {
        color: #fff;
    }



    @include mq($from: desktop){
        padding: 9rem 5rem;
    }
}


/* Market hours */
.market-hours {
    background-color: #fff;
    padding: 2em;
    text-align: center;
    margin-top: 1rem;

    img {

    }

    &__days {
        @extend %unstyled-list;
        color: #ff685c;
        font-size: 1.8rem;
        margin-top: .75em;

        li {
            margin: .25em 0;
        }
    }
}



/* Parking hours */
.parking-hours {
    background-color: #fff;
    padding: 2em;
    text-align: center;
    margin-top: 1rem;

    &__days {
        @extend %unstyled-list;
        //color: #ff685c;
        font-size: 1.8rem;
        margin-top: .75em;

        li {
            margin: .25em 0;
        }
    }
}

/* Empty hours */
.empty-hours {
    background-color: transparent;
    padding: 2em;
    text-align: center;
    margin-top: 1rem;

    @include mq($from: desktop){
        padding: 4rem 2em;
        .parking-hours + & {
            margin-left: 1rem;
        }
    }

}


/* Store hours */
.store-hours {
    background-color: #fff;
    padding: 2em;
    text-align: center;
    margin-top: 1rem;

    img {

    }

    &__days {
        @extend %unstyled-list;
        color: #ffffff;
        font-size: 1.8rem;
        margin-top: .75em;

        li {
            margin: .25em 0;
        }
    }
}


/* Restaurants hours */
.restaurants-hours {
    display: block;
    color: #fff;
    padding: 2em;
    text-decoration: none;
    margin-top: 1rem;

    svg {
        display: block;
        margin: 0 auto;
        width: 3.2rem;
        height: 3.2rem;
    }

    @include mq($from: desktop){
        padding: 4rem 2em;

        .market-hours + & {
            margin-left: 1rem;
        }
    }

    &__days {
        @extend %unstyled-list;
        color: #ffffff;
        font-size: 1.8rem;
        margin-top: .75em;

        li {
            margin: .25em 0;
        }
    }
}
