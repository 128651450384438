/*------------------------------------*\
    #SERVICES LIST
    Display services as list of square
    with icon
\*------------------------------------*/

.services-list {
    @extend %unstyled-list;
    @extend .row;
    @include flexbox();
    justify-content: flex-start;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &.-small {
        justify-content: center;
    }

    li {
        width: 49.5%;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        margin: 1.2rem 0;

        &[aria-hidden="true"] {
            display: none;
        }

        &.-highlighted {
            width: 99%;
        }
    }

    @include mq($from: tablet){
        li {
            width: 24.5%;

            &.-highlighted {
                width: 98%;
            }
        }
    }

    @include mq($from: desktop){
        &.-small {
            li {
                width: 16.66666%;
            }
        }
    }
}

/* A service square */
.service-tile {
    position: relative;
    border: .2rem solid #fff;
    outline: .1rem solid $c-darkergray;
    background-color: $c-darkergray;
    font-size: 1.4rem;
    color: $c-lightgray;
    text-align: center;

    &::before {
        content: '';
        display: block;
        padding-top: 100%;

        .-highlighted & {
            padding-top: 50%;
        }
    }

    // No link
    &.-no-link {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include flexbox();
        align-items: center;
        justify-content: center;
        padding: 1rem;
        color: #fff;
        text-decoration: none;
        transition: background 250ms ease-in, color 250ms ease-in;

        span {
            display: block;
            width: 100%;
        }
    }

    &__icon {
        fill: currentColor;
        width: 15vw;
        height: 15vw;
        display: block;
        margin: 0 auto .5em;
        max-width: 10rem;
        max-height: 10rem;
    }

    @include mq($from: tablet){

        &__icon {
            width: 9vw;
            height: 9vw;
            max-width: 8rem;
            max-height: 8rem;
        }
    }
}
