.shop-card {
    @include card;

    &.-inline {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .shop-card__content {
            text-align: center;

            .shop-card__title {
                color: $c-darkgray;
            }
        }
    }

    &__schedule {
        color: $c-medgray;
        font-size: .8em;
        margin-top: 2rem;
        list-style: none;
        padding: 0;
        text-transform: uppercase;

        li:last-of-type {
            margin-top: 1rem;
            display: none;
        }

        &-item {
            line-height: 1.3em;

            &.strong {
                font-size: 1em;
            }

            div {
                width: 45%;
                display: inline-block;

                &:first-of-type {
                    text-align: right;
                    padding-right: 1rem;
                }

                &:last-of-type {
                    text-align: left;
                    padding-left: 1rem;
                }
            }
        }
    }

    &__job {
        color: $c-medgray;
        font-size: 1.5rem;
        margin-top: 2rem;
        list-style: none;
        padding: 0;

        position: relative;
        z-index: 50;

        &-item {
        }
    }

    @include mq($from: tablet) {
        .shop-card__media {
            max-width: 14.5rem;
            margin: 0 auto;
        }

        .shop-card__content {
            text-align: center;
        }

        &.-inline {
            .shop-card__media {
                margin: 0;
            }

            .shop-card__content {
                padding: 0;
            }

            .logo-square__sales {
                transform: translate(-67%, -67%) rotate(-45deg);
            }
        }
    }

    @include mq($until: tablet) {
        &.-inline {
            .shop-card__media {
                img {
                    width: 80%;
                }
            }

            .logo-square__sales {
                transform: translate(-62%, -62%) rotate(-45deg);
                font-size: 1.1rem;
            }
        }

        &__schedule {
            margin-top: 0;

            li:last-of-type {
                display: block;
            }

            &-item {
                display: none;

                div {
                    width: 100%;
                    text-align: center !important;
                    padding-left: 0 !important;
                    padding-right: 0 !important;

                    &:first-of-type {
                        display: none;
                    }
                }

                &.strong {
                    display: block;
                    font-weight: normal;
                }
            }
        }
    }
}
