/*------------------------------------*\
    #IMAGE CARD
    Structure :
    - Image
    - Title

    Used for shop-categories listing
\*------------------------------------*/

.img-card {
    text-align: center;
    position: relative;
    transition: margin-bottom 300ms ease-in;

    &::after {
        content: '';
        width: 0;
        height: 0;
        border-top: 1.5em solid transparent;
        border-right: 1.5em solid transparent;
        border-bottom: 1.5em solid transparent;
        border-left: 1.5em solid transparent;
        position: absolute;
        left: 50%;
        opacity: 0;
        bottom: -4.2rem;
        z-index: -1;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        transition: opacity 250ms ease-in;
    }

    /* Image */
    &__media {
        img {
            width: 100%;
        }
    }

    /* Title */
    &__title {
        font-size: 1.4rem;
        margin: 0;

        a {
            color: $c-txt;
            text-transform: uppercase;
            display: block;
            padding: 1.5em;
            background-color: #fff;
            text-decoration: none;
            transition: background-color 250ms ease-in, color 250ms ease-in;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: $c-txt;
                color: #fff;
            }
        }
    }



    /* Has panel */
    &.-has-panel {
        &::after {
            opacity: 1;
            z-index: 10;
        }

        + .underpanel {
            z-index: 10;
            opacity: 1;
        }
    }
}
