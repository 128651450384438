.search-results {
    background-color: #fff;
    padding: 4rem 0;

    &__list {
        @extend %unstyled-list;
        max-width: 56rem;
        margin: 0 auto;

        > li {
            & + li {
                border-top: 1px solid $c-lightgray;
            }
        }
    }

    &__no-result {
        max-width: 56rem;
        margin: 0 auto;
        text-align: center;
    }
}

.search-result {
    position: relative;
    padding: 2rem;
    @extend %clearfix;

    &__title {
        margin-bottom: 1rem;

        svg {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: .5rem;
        }
    }

    &__image {
        display: none;
    }

    &__summary {
        line-height: 1.4;
    }

    &__link {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    @include mq($from: tablet) {
        padding-left: 0;
        padding-right: 0;


        &__image {
            display: inline;
            float: left;
            margin-right: 1rem;
        }
    }
}
