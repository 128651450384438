/*------------------------------------*\
    #ACCESS
\*------------------------------------*/

.access {
    color: #fff;

    a {
        color: #fff;
    }

    &__map {
        > div {
            min-height: 40rem;
        }

        iframe {
            max-width: 100%;
        }
    }

    &__body {
        padding: 3em;
    }

    /* Tabs */
    &__tabs {
        @extend %unstyled-list;
        @include flexbox();

        li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            flex: 1;
            border-bottom: 1px solid rgba(#fff, .5);

            &:not(:first-child) {
                border-left: 1px solid rgba(#fff, .5);
            }
        }

        a {
            text-decoration: none;
            padding: 2em;
            display: table;
            font-size: 1.4rem;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            transition: background-color 250ms ease-in;
            height: 100%;
            width: 100%;
        }
    }

    .tabs-panel {
        &[aria-hidden="true"] {
            display: none;
        }
    }


    @include mq($until: tablet){
        &__tabs {
            a {
                padding: 1em .5rem;
                font-size: 1.2rem;
            }
        }
    }

    @include mq($from: tablet){
        &__content {
            width: 50%;
        }

        &__body {
            padding: 5em;
        }

        &__map {
            width: 50%;
            position: relative;

            > div,
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}



/* Access coords */
.access-coords {
    @include mq($from: tablet) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}



/* Route */
.access-route {
    padding: 2em;

    &__type {
        @extend %unstyled-list;
        text-align: center;
        margin-bottom: 1.5em;

        li {
            display: inline-block;
            vertical-align: middle;
            padding: 0 1em;
            position: relative;

            &:not(:first-child){
                border-left: 1px solid rgba(#fff, .25);
            }
        }

        [type="radio"]{
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked {
                + label {
                    svg {
                        opacity: 1;
                    }
                }
            }
        }

        svg {
            width: 3.6rem;
            height: 3.6rem;
            opacity: .65;
        }
    }

    &__address {
        position: relative;
        @extend .autofillColored;

        [type="text"] {
            border: 1px solid rgba(#fff, .8);
            background-color: transparent;
            height: auto;
            width: 100%;
            color: #fff;
            padding-right: 5rem;
        }

        [type="submit"] {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            border: 0;
            border-left: 1px solid rgba(#fff, .8);
            background-color: transparent;
            color: #fff;
            transition: background-color 250ms ease-in, color 250ms ease-in;

            &:hover,
            &:focus,
            &:active {
                background-color: #fff;
                color: $c-txt;
            }
        }
    }


    ::-webkit-input-placeholder {
        color: #fff;
    }

    input:-moz-placeholder,
    input::-moz-placeholder,
    textarea:-moz-placeholder,
    textarea::-moz-placeholder {
        color: #fff;
    }
}




/* Address */
.access-address {
    padding: 2rem;
    font-style: normal;

    &__title {
        text-align: center;
        font-size: 2.4rem;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 2em;
    }

    &__coords {
        @extend %unstyled-list;
        font-size: 2rem;

        li {
            margin: 1em 0;
            display: flex;

            span.icon {
                display: inline-block;
                min-width: 5rem;
                text-align: center;

            }
        }

        a {
            color: #fff;
        }
    }


    @include mq($from: desktop){
        padding: 5rem;

        &__coords {
            margin-left: 2em;
        }
    }
}
