.push-menu {
    position: fixed;
    height: 100%;
    width: 350px;
    top: 0;
    z-index: 1001;
    opacity: 0;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    overflow-y: auto;

    &.pm_open {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    &--right {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        transform: translateX(100%);
        box-shadow: 0 -20px 50px 40px rgba(0, 0, 0, 0.15);
        right: 0;
    }

    &--left {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        transform: translateX(-100%);
        box-shadow: 0 20px 50px 40px rgba(0, 0, 0, 0.15);
        left: 0;
    }

    &__header {
        position: relative;
        width: 100%;
        font-size: 1.25em;
        letter-spacing: .03em;

        > .push-menu__title {
            padding: 2rem 5rem 2rem 2.8rem;
        }

        > .push-menu__close {
            position: absolute;
            top: 2rem;
            right: 2rem;
            background: transparent;
            border: none;
            outline: none;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
        }

    }

    &__title {
        font-size: 1em;
        padding: 1rem 2.8rem;
        font-weight: bold;
    }

    &__content {
        padding: 2rem 2.8rem 2.8rem;
        text-align: left;

        .tabs {
            text-align: left;
        }
    }

    &.-darkgray {
        background-color: $c-darkgray;
        color: #fff;

        .push-menu {
            &__header {
                > .push-menu__title {
                    background-color: lighten($c-darkgray, 8%);
                }

                > .push-menu__close {
                    color: #fff;

                    &:hover {
                        color: darken(#fff, 10%);
                    }
                }
            }

            &__title {
                background-color: lighten($c-darkgray, 3%);
            }
        }
    }

    @include mq($until: mobile) {
        width: 100%;
    }
}

body > .pm_overlay {
    display: none !important;
    //width: 100%;
    //height: 100%;
    //padding: 0;
    //margin: 0;
    //position: fixed;
    //z-index: 1000;
    //background: rgba(0,0,0,0.2);
    //visibility: hidden;
    //left: 0;
    //top: 0;
    //cursor: pointer;
    //-webkit-transition: all .3s ease-in-out;
    //-moz-transition: all .3s ease-in-out;
    //-o-transition: all .3s ease-in-out;
    //transition: all .3s ease-in-out;

    &.pm_show {
        //display: block;
    }

    &.pm_hide {
        //display: none;
    }
}
