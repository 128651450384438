.foodcourt-list {
    &__header {
        padding: 5rem 0;
        color: #fff;
        text-align: center;
        margin-bottom: $grid-gutter-width * 1em;
        background-image: url(../../images/foodcourt-banner.jpg);
        -webkit-background-size: cover;
        background-size: cover;
        background-position: center center;
    }

    &__title {
        font-size: 3.8rem;
        margin-bottom: 1rem;
        word-wrap: break-word;
    }

    &__subtitle {
        font-size: 1.8rem;
        font-style: italic;
        font-family: $ff-serif;
    }
    
    &__pre-text {

    }

    &__post-text {

    }

    &__stores {
        @extend %unstyled-list;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 5rem;
        margin-bottom: 5rem;

        > li {
            flex: 0 0 50%;
            position: relative;
            background-color: #fff;
            padding: 1.5rem;
            text-align: center;

            &:hover {
                transform: scale(1.2);
                z-index: 100;
                transition: transform 200ms;
            }

            &:before {
                content:'';
                float: left;
                padding-bottom: 100%;
            }

            &:nth-child(11n+5),
            &:nth-child(11n+6),
            &:nth-child(11n+10) {
                background-color: #fff;
            }

            &:nth-child(2) {
                background-image: url(../../images/foodcourt-panorama1.jpg);
                -webkit-background-size: cover;
                background-size: cover;
                background-position: center center;
                display: none;
            }

            &:nth-child(9) {
                background-image: url(../../images/foodcourt-panorama2.jpg);
                -webkit-background-size: cover;
                background-size: cover;
                background-position: center center;
            }

            &:nth-child(4),
            &:nth-child(6) {
                position: relative;
                -webkit-background-size: cover;
                background-size: cover;
                background-position: center center;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    opacity: .8;
                }
            }

            &:nth-child(4) {
                background-image: url(../../images/foodcourt-background1.jpg);
            }

            &:nth-child(6) {
                background-image: url(../../images/foodcourt-background2.jpg);
            }

            &.-twice {
                flex-basis: 50%;

                &:before {
                    padding-bottom:50%;
                }
            }
            
            a {
                position: relative;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

    @include mq($from: tablet)
    {
        &__header {
            padding: 10rem 0;
        }

        &__title {
            font-size: 6rem;
        }

        &__stores {
            > li {
                flex-basis: 25%;
                &:nth-child(2),
                &:nth-child(10) {
                    display: block;
                }
            }
        }
    }
}

