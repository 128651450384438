/*------------------------------------*\
    #NEWSLETTER SIMPLE
    Minimal template for newsletter
\*------------------------------------*/
.newsletter-simple {
    .footer & {
        &__subtitle {
            display: none;
        }
        &__link {
            text-decoration: none;
            color: inherit;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
}

.newsletter-simple-form {
    position: relative;

    [type="email"] {
        color: $c-txt;
        background-color: transparent;
        border: 1px solid currentColor;
        font-size: 1.4rem;
        width: 100%;
        padding-right: 3em;
    }

    input::-moz-placeholder {
        color: $c-txt;
    }

    ::-webkit-input-placeholder {
        color: $c-txt;
    }

    &__submit {
        color: $c-txt;
        background-color: transparent;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: .5em;
        border: 0;
        height: 4.3rem;
        border-left: 1px solid currentColor;
        text-transform: uppercase;
        transition: background-color 250ms ease-in, color 250ms ease-in;


        &:hover,
        &:focus,
        &:active {
            background-color: #fff;
        }
    }
}
