/*------------------------------------*\
    #PICTURE GALLERY
    Open a modal (with MagnificPopup)
    when clicked
\*------------------------------------*/

.pics-gallery {
    @extend %unstyled-list;
    @extend %clearfix;
    margin: 3.5em 0;

    &:last-child {
        margin-bottom: 0;
    }

    > li {
        float: left;
        width: 50%;
        position: relative;
        overflow: hidden;
    }

    &__item {
        display: inline-block;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: background-color 400ms ease-in;
            z-index: 5;
        }

        &:hover,
        &:focus,
        &:active {
            &::before {
                background-color: rgba(#000, .25);
            }

            img {
                transform: scale(1.05);
            }
        }
    }

    img {
        transition: transform 1s linear;
    }


    /* Add to favorites */
    .add-favorites {
        position: absolute;
        top: 0;
        right: 0;
    }

    /* Sharing */
    .sharing-toggle {
        position: absolute;
        top: 2.5rem;
        right: 0;
    }


    @include mq($from: tablet){
        li {
            width: 33.3333%;
        }
    }


    @include mq($from: desktop){
        li {
            width: 25%;
        }
    }
}
