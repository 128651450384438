/*------------------------------------*\
    #LOGO CARD
    Structure :
    - Image (logo)
    - Call to action

    Used for shop listing
\*------------------------------------*/

.logo-card {
    background-color: #fff;
    padding: 6rem 3rem 8rem;
    text-align: center;
    position: relative;

    &__logo {
        margin-bottom: 1.5em;

        img {
            height: 8.5rem;
        }
    }

    &__cta {
        @include centerer(true, false);
        bottom: 3rem;
        width: calc(100% - 6rem);
    }
}
