/*------------------------------------*\
    #TAGS
\*------------------------------------*/
.tags-list {
    @extend %unstyled-list;

    li {
        display: inline-block;
        vertical-align: middle;
        margin: .25em 0;
    }
}


/* Tag */
.tag {
    display: inline-block;
    font-size: 1.5rem;
    border: 1px solid $c-txt;
    color: $c-txt;
    padding: .25em 1em;
    text-decoration: none;
    transition: background-color 250ms ease-in, color 250ms ease-in;

    &:hover,
    &:focus,
    &:active {
        background-color: $c-txt;
        color: #fff;
    }
}
