.shop-calendar {
    &__title {
        text-align: center;
        margin-bottom: 1rem;
        color: #fff;
    }

    &__legend {
        display: inline-block;
        line-height: 3rem;
        margin: 1rem 1rem 0 0;
        height: 3rem;
        color: #fff;

        .box {
            display: inline-block;
            width: 2.2rem;
            height: 2.2rem;
            margin-right: .5rem;
            vertical-align: middle;

            @for $i from 1 through length($c-calendar) {
                &.-color#{$i} {
                    background-color: nth($c-calendar, $i);
                }
            }
        }
    }


    /* Full Calendar override */
    .fc {
        h2 {
            font-size: 2.5rem;
        }

        .fc-toolbar {
            color: #fff;

            .fc-icon {
                &:after {
                    color: #fff;
                    text-shadow: none;
                }
            }
        }
    }

    .fc-view {
        .fc-body, .fc-head {
            border: 0;

            tr {
                td {
                    border: 0;
                    color: #fff;

                    .fc-row {
                        border: 0;
                    }
                }
            }

            thead, thead th {
                border: 0;
            }
        }
    }

    .fc-state-default {
        background: none;
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .fc-unthemed th, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row,
    .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td {
        border-color: #fff;
    }

    .fc-unthemed {
        max-width: 45rem;
        margin: auto;
    }

    .fc-unthemed td {
        border: 3px solid #fff;
    }

    .fc table {
        border-collapse: separate;
    }

    .fc-basic-view .fc-body .fc-row {
        min-height: auto;
    }

    .fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
        float: none;
        display: block;
        line-height: 3rem;
        width: 3rem;
        height: 3rem;
        border: 1px solid transparent;
    }

    .fc-day-header {
        font-weight: normal;
        font-family: $ff-heading;
        font-size: 1.6rem;
    }

    .fc-day-top {
        text-align: center;
    }

    .fc-unthemed td.fc-today {
        background: none;
    }

    .fc-bgevent {
        position: relative;
        background: none;
        opacity: .7;

        &:before {
            content: "";
            display: block;
            width: 3.5rem;
            height: 3.5rem;
        }

        @for $i from 1 through length($c-calendar) {
            &.-color#{$i}:before {
                background-color: nth($c-calendar, $i);
            }
        }
    }

    .-kids & {
        background-color: $c-kids;

        .fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
            border-color: $c-kids;
        }


    }

    @include mq($from: tablet) {
        .-kids & {
            &:after {
                content: '';
                position: absolute;
                left: -65px;
                bottom: 75px;
                background: url('../../images/kids/balloons_2.png') no-repeat;
                width: 120px;
                height: 120px;
                z-index: 2;
            }

            &:before {
                content: '';
                position: absolute;
                right: -13px;
                top: 50%;
                background: url('../../images/kids/balloons_5.png') no-repeat;
                width: 55px;
                height: 55px;
                z-index: 2;
            }
        }
    }
}
