/*------------------------------------*\
    #NEWSLETTER BANNER
    Newsletter form display as section
\*------------------------------------*/

.newsletter-banner {
    position: relative;
    max-width: 60rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
    color: #fff;

    /* Triangle */
    &::before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        border-top: 1.5em solid rgba(#fff, .6);
        border-right: 2.5em solid transparent;
        border-bottom: 1.5em solid transparent;
        border-left: 2.5em solid transparent;
        @include centerer(true, false);
        top: -4rem;
    }

    &__title {
        text-transform: uppercase;
        font-size: 1.8rem;
        margin-bottom: 2em;
        text-align: center;
    }

    &__subtitle {
        font-family: $ff-serif;
        font-weight: normal;
        font-style: italic;
        //color: $c-txt;
        font-size: 2.2rem;
        text-align: center;
    }


    @include mq($from: wide){
        &::before {
            border-top: 1em solid rgba(#fff, .6);
            border-right: 2em solid transparent;
            border-bottom: 1em solid transparent;
            border-left: 2em solid transparent;
            top: -5rem;
        }
    }
}

.newsletter-banner-form {
    position: relative;

    .field-wrapper {
        position: relative;
    }

   [type="text"],
   [type="email"] {
        color: #fff;
        background-color: transparent;
        border: 1px solid currentColor;
        font-size: 1.8rem;
        width: 100%;
       padding-right: 3em;
   }

    input::-moz-placeholder {
        color: #fff;
    }

    ::-webkit-input-placeholder {
        color: #fff;
    }

    &__submit {
        color: #fff;
        background-color: transparent;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        padding: .5em;
        border: 0;
        border-left: 1px solid currentColor;
        text-transform: uppercase;
        transition: background-color 250ms ease-in, color 250ms ease-in;

        &:hover,
        &:focus,
        &:active {
            background-color: #fff;
        }
    }

}

.newsletter--form {

    [type="text"],
    [type="email"] {
        color: #fff;
        background-color: transparent;
        border: 1px solid currentColor;
        font-size: 1.8rem;
        width: 100%;
        padding-right: 3em;
    }

    input::-moz-placeholder {
        color: #fff;
    }

    ::-webkit-input-placeholder {
        color: #fff;
    }

    &__standard {

        max-width: 60rem;
        margin: 2rem auto;

        [type="text"],
        [type="email"] {
            color: inherit;
            background-color: transparent;
            border: 1px solid currentColor;
        }

        label {
            display: block;
        }

        input::-moz-placeholder,
        ::-webkit-input-placeholder {
            color: inherit;
        }

    }
}
