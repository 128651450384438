.hbanner {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
    z-index: 5;
    justify-content: center;
    text-align: center;
    color: #fff;
    min-height: 40rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &.fullpage {
        height: calc(100vh - 12.5rem); /* The full page height without the menu height */

        &:after {
            content: '\f078';
            position: absolute;
            bottom: 125px;
            width: 100%;
            height: 50px;
            text-align: center;
            font-family: 'Font Awesome 5 Pro';
            font-size: 2em;
            animation: arrow-jump 1.2s linear infinite;
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        flex-flow: column;

        &.top-center {
            text-align: center;
            margin-top: 5rem;
        }

        &.top-left {
            text-align: left;
            margin-left: 10rem;
            margin-top: 5rem;
        }

        &.top-right {
            text-align: right;
            margin-right: 10rem;
            margin-top: 5rem;
        }

        &.middle-center {
            justify-content: center;
            text-align: center;
        }

        &.middle-left {
            justify-content: center;
            text-align: left;
            margin-left: 10rem;
        }

        &.middle-right {
            justify-content: center;
            text-align: right;
            margin-right: 10rem;
        }

        &.bottom-center {
            justify-content: flex-end;
            text-align: center;
            margin-bottom: 5rem;
        }

        &.bottom-left {
            justify-content: flex-end;
            text-align: left;
            margin-left: 10rem;
            margin-bottom: 5rem;
        }

        &.bottom-right {
            justify-content: flex-end;
            text-align: right;
            margin-right: 10rem;
            margin-bottom: 5rem;
        }

        .hbanner__title {
            h1, h2,  h3 {
                margin: 0 0 1.5rem;
            }

            h1 {
                font-size: 5em;
                display: inline-block;

                strong {
                    position: relative;
                    z-index: 1;
                    background: linear-gradient(120deg, $c-primary 0%, lighten($c-primary, 12%) 100%);
                    background-repeat: no-repeat;
                    background-size: 80% 0.2em;
                    background-position: 0 82%;
                    transition: background-size 0.25s ease-in;

                    &:hover {
                        background-size: 100% 82%;
                    }

                    a {
                        color: inherit;
                        text-decoration: none;
                    }
                }
            }

            h2 {
                font-size: 3.5em;
            }

            .description {
                font-size: 1.7em;
                font-weight: normal;
            }

            a {
                &.btn {
                    display: inline-block;
                    padding: .5em 1.5em;
                    text-decoration: none;
                    margin-top: 2rem;
                }
            }
        }
    }

    &__video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;

        video {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &.--overlay-dark {
        color: #fff;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: rgba(0, 0, 0, .15);
        }

        &::after {
            color: #fff;
        }
    }

    &.--overlay-light {
        color: #333;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: rgba(255, 255, 255, .5);
        }

        &::after {
            color: #333;
        }

        .hbanner__content .hbanner__title a.btn {
            color: #333;
            border: 1px solid #333;
        }
    }
}

@media screen and (min-width: 62em) {
    section.hbanner {
        height: 67rem;
    }
}

@media screen and (min-width: 48em) {
    section.hbanner {
        height: 73rem;
    }
}

@media screen and (max-width: 48em) {
    section.hbanner.fullpage {
        height: 40rem;

        &:after {
            content: none;
        }
    }

    section.hbanner .hbanner__content .hbanner__title h1 {
        font-size: 3em;
    }

    section.hbanner .hbanner__content .hbanner__title h2 {
        font-size: 2.5em;
    }

    section.hbanner .hbanner__content .hbanner__title h3 {
        font-size: 1em;
    }

    section.hbanner .hbanner__content .hbanner__title a.btn {
        padding: .5em 1em;
        margin-top: 2rem;
    }

    section.hbanner .hbanner__content.top-left {
        margin-left: 1.5em;
    }

    section.hbanner .hbanner__content.top-right {
        margin-right: 1.5em;
    }

    section.hbanner .hbanner__content.middle-left {
        margin-left: 1.5em;
    }

    section.hbanner .hbanner__content.middle-right {
        margin-right: 1.5em;
    }

    section.hbanner .hbanner__content.bottom-left {
        margin-left: 1.5em;
    }

    section.hbanner .hbanner__content.bottom-right {
        margin-right: 1.5em;
    }
}

@-webkit-keyframes arrow-jump {
    0%   { opacity: 0;}
    100% { opacity: 1;
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        transform: translateY(10px);
    }
}

@keyframes arrow-jump {
    0%   { opacity: 0;}
    100% { opacity: 1;
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        transform: translateY(10px);
    }
}
