/*------------------------------------*\
#FONT FACE
\*------------------------------------*/
@font-face {
  font-family: 'ocr-a-std';
  src: url('../../fonts/ocr-a-std.otf') format('opentype');
}

@font-face {
    font-family: 'Playfair';
    src: url('../../fonts/playfairdisplay-bold-webfont.woff2') format('woff2'),
        url('../../fonts/playfairdisplay-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;

}
@font-face {
    font-family: 'Playfair';
    src: url('../../fonts/playfairdisplay-bolditalic-webfont.woff2') format('woff2'),
        url('../../fonts/playfairdisplay-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;

}
@font-face {
    font-family: 'Playfair';
    src: url('../../fonts/playfairdisplay-italic-webfont.woff2') format('woff2'),
        url('../../fonts/playfairdisplay-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;

}
@font-face {
    font-family: 'Playfair';
    src: url('../../fonts/playfairdisplay-regular-webfont.woff2') format('woff2'),
        url('../../fonts/playfairdisplay-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// Import Source Sans Pro from Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

/* ==========================================================================
	Typography style based on KNACSS
	http://www.knacss.com/
========================================================================== */
html {
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%;
}
/* Stupid IE11 - https://connect.microsoft.com/IE/feedback/details/816709/ie-11-calculating-font-sizes-wrong-when-setting-the-bodys-font-size-in-relative-units */
_:-ms-fullscreen, :root {
    font-size: calc(1em * .625);
}

body {
    font-family: $ff-sans-serif;
    font-size: $fz-base;
	color: $c-txt;
    line-height: 1.5;
    background-color: $c-lightergray;
    overflow-x: hidden;
}

/*
* Titles
*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1-like,
.h2-like,
.h3-like,
.h4-like,
.h5-like,
.h6-like {
    font-family: $ff-heading;
    line-height: 1;
    font-weight: bold;
}

h1,
.h1-like {
	font-size: 3.2rem;
}

h2,
.h2-like {
	font-size: 3rem;
}

h3,
.h3-like {
	font-size: 2.4rem;
}

h4,
.h4-like {
	font-size: 2rem;
}

h5,
.h5-like {
	font-size: 1.8rem;
}

h6,
.h6-like {
	font-size: 1.6rem;
}


/*
* Alternate font sizing
*/
.smaller {
	font-size: 0.71em;
}

.small {
	font-size: 0.86em;
}

.big {
	font-size: 1.14em;
}

.bigger {
}

.biggest {
	font-size: 1.43em;
}


em,
.em,
i {
	font-style: italic;
}

.no-em {
	font-style: normal;
}

strong,
.strong,
b {
	font-weight: bold;
}

.no-strong {
	font-weight: normal;
}

sup,
sub {
	vertical-align: 0;
	position: relative;
}
sup {
	bottom: 1ex;
}
sub {
	top: .5ex;
}


/*
* Avoid top margins on first content element
*/
p:first-child,
.p-like:first-child,
ul:first-child,
ol:first-child,
dl:first-child,
blockquote:first-child,
pre:first-child,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}


/**
* Links
*/
a {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

blockquote:first-child,
pre:first-child,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}


/**
* Links
*/
a {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}
