.opening {
    &__sentences {
        display: flex;
        justify-content: space-around;
        flex: 1 1 0px; /* Keep the "px" to ensure IE compatibility */
        min-width: 0;
        text-align: center;
    }

    &__sentence {
        font-size: 1.3rem;
    }

    &__item {
        text-transform: uppercase;
        font-weight: bold;
        margin: 0 2rem;
        font-size: 2em;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;

        &.--badged {
            &:before {
                content: '';
                width: 10px;
                height: 10px;
                position: absolute;
                border-radius: 50px;
                left: -20px;
                vertical-align: middle;
            }

            &.opening__item-open:before {
                background: rgb(49, 179, 49);
                animation: pulse 2s infinite;
                transform: scale(1);
                box-shadow: 0 0 0 0 rgba(49, 179, 49, 1);
            }

            &.opening__item-close:before {
                background: #ff0000;
            }
        }
    }

    a.opening__item {
        color: #1a1a1a;
        transition: all ease 200ms;
        text-decoration: none;

        &:hover {
            transform: translateY(-3px);
        }
    }

    /** Iconized **/
    &.--iconized {
        .opening__sentences {
            text-align: left;
        }

        .opening__sentence {
            margin-left: 1.5rem;
        }

        @media (max-width: $screen-lg) {
            .opening__sentences {
                text-align: center;
            }

            .opening__sentence {
                margin-top: 1rem;
                margin-left: 0;
            }
        }
    }

    /** Flat **/
    &.--flat {
        margin: 1.2rem;

        .opening__item {
            margin: 0;
            width: auto;
        }

        .opening__sentence {
            display: block;
            margin: 0;
            font-size: .7em;
        }
    }

    @media (max-width: $screen-lg) {
        &__sentences {
            flex-direction: column;
        }

        &__item {
            margin: 2rem;
            flex-direction: column;
            align-items: center;
            width: auto;
            text-align: center;
            font-size: 1.5em;

            &.--badged:before {
                position: initial;
            }
        }

        &__sentence {
            font-size: 1.2rem;
        }
    }
}

.menu-opening {
    font-weight: bold;
    text-align: left;
    padding-left: 1rem;
    display: block;
    position: relative;

    > div.opening__label {
        display: inline-block;
    }

    > div.opening__schedule {
        font-size: .8em;
    }

    &:before {
        content: '';
        position: absolute;
        display: inline-block;
        border-radius: 50px;
        width: 10px;
        height: 10px;
        left: -10px;
        top: 1rem;
    }

    &.--open:before {
        background-color: rgb(49, 179, 49);
        animation: pulse 2s infinite;
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(49, 179, 49, 1);
    }

    &.--close:before {
        background-color: red;
    }

    @media (max-width: $screen-md) {
        text-align: center;
        padding-left: 0;
    }
}

a.menu-opening:hover {
    text-decoration: none;
}

/** Color modifiers **/
.-colored-primary, .-medgray, .-darkgray {
    .opening .opening__sentences .opening__item {
        color: #fff;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(49, 179, 49, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(49, 179, 49, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(49, 179, 49, 0);
    }
}
