.sitemap {
    @extend %unstyled-list;

    &-item {
        margin: 3rem 0;

        .listing > &__link {
            margin-bottom: 0;

            a {
                text-decoration: none;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
}
