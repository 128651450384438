.smartbanner {
    &-android {
        box-shadow: 0 3px 15px 0 #ccc;
        background: #f2f2f2;

        .smartbanner-info {
            text-shadow: none;
            color: #666;
        }

        .smartbanner-title {
            color: #333;
        }

        .smartbanner-button {
            box-shadow: none;
            color: #000;

            &-text {
                background: none;
            }
        }

        .smartbanner-close {
            background: none;
            text-shadow: none;
            box-shadow: none;
            color: #666;
        }
    }
}