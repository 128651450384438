.sidebar {
    background-color: #fff;
    list-style: none;
    margin: 0;
    padding: 0;

    .dropdown-placeholder {
        display: none;
        font-size: 1.4rem;
        margin-bottom: 0;
        padding: .9em 1.7em;
        color: $c-darkgray;
    }

    &__item {
        font-size: 1.4rem;
        margin-bottom: 0;
        border-left: 3px solid transparent;

        a {
            transition: background-color 250ms ease-in, color 250ms ease-in;
            color: $c-darkgray;
            display: block;
            padding: .9em 1.7em;
            text-decoration: none;
        }

        &.active {
            font-weight: bold;
            border-left: 3px solid #1e1e1e;
        }
    }

    @include mq($until: tablet) {
        &.sidebar-mobile-dropdown {
            margin-bottom: 2em;

            .dropdown-placeholder {
                display: block;

                .dropdown-icon {
                    height: 1.2em;
                    width: 1.2em;
                    float: right;
                }
            }

            .sidebar__item {
                display: none;
            }
        }
    }
}