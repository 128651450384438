.card {
    @include card;

    &.active {
        display: block;
    }
}

.list-card {
    @include card;
    margin-top: 1em;
    display: block;

    @include mq($from: tablet) {
        padding: 0;
    }

    &__title {
        text-align: left;
        text-transform: none;
        font-weight: bold;
        font-size: 1.4rem;
        width: 100%;

        .title-label {
            width: 90%;
            float: left;
        }

        .title-icon {
            width: 10%;
            float: right;

            svg {
                max-width: 100%;
                height: 1.6em;
            }

            svg.reverse {
                transform: rotate(180deg);
            }
        }

        a {
            transition: background-color 250ms ease-in, color 250ms ease-in;
            color: $c-darkgray;
            display: block;
            padding: 1.5em 2.3em 1.5em 1.5em;
        }

        a:not(.--no-effect) {
            &:hover,
            &:focus,
            &:active {
                background-color: $c-txt;
                color: #fff;
            }
        }
    }

    &__content {
        text-align: left;
        padding: 0 1.5em 1.5em;
    }
}
