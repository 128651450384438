.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;

  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &-primary {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
}

