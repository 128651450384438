/*------------------------------------*\
    #BASE
\*------------------------------------*/
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

::-moz-selection {
    color: $c-darkergray;
    text-shadow: none;
}

::selection {
    color: $c-darkergray;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px dotted #aaa;
    margin: 0 0 5rem 0;
    padding: 0;
}

audio,
canvas,
img,
svg,
video {
    vertical-align: middle;
}

img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
video {
    max-width: 100%;
}

img {
    height: auto;
}

.main,
.main-nav {
    &:focus {
        outline: 0;
    }
}

.main {
    overflow: hidden;

    @include mq($from: desktop) {
        margin-top: 12.55rem;
    }
}

/*
* SECTION
*/
.section {
    padding: 2.7rem 0;

    &.-nopad {
        padding: 0!important;
    }

    /* Modifier */
    &.-white {
        background-color: #fff;
    }

    &.-lightergray {
        background-color: $c-lightergray;
    }

    &.-lightgray {
        background-color: $c-lightgray;
    }

    &.-medgray {
        background-color: $c-medgray;
    }

    &.-darkgray {
        background-color: $c-darkgray;
        color: #fff;
    }

    &.-darkergray {
        background-color: $c-darkergray;
        color: #fff;
    }

    &.-colored-secondary-light {
        background-color: rgba($c-secondary, .10);
    }

    &.-colored-kids {
        background-color: $c-kids;
    }

    &.-services {
        .section__header {
            margin-bottom: 3rem;
        }
    }

    &.-culture {
        &:last-child {
            padding-bottom: 0;
        }

        @include mq($from: desktop) {
            padding-top: 8rem;
            padding-bottom: 8rem;
        }

        @include mq($from: wide) {
            padding-top: 10rem;
            padding-bottom: 10rem;
        }

        .section__title {
            text-align: left;
            text-transform: none;

            &:before {
                content: '';
                display: inline-block;
                width: 3rem;
                border: .1rem solid $c-txt;
                vertical-align: middle;
                margin: 0 2rem;
            }
        }

        .section__subtitle {
            text-align: left;
            text-transform: none;
            font-style: normal;
        }

        a.section__subtitle {
            &:after {
                content: ' →';
            }
        }
    }

    &.-kids {
        a.section__subtitle {
            &:after {
                content: ' →';
            }
        }
    }

    &.-alt {
        .section__header {
            text-align: left;
        }

        .section__subtitle {
            font-size: 1.6rem;
            text-decoration: none;
        }
    }

    /* Elements */
    &__header {
        text-align: center;
        margin-bottom: 2em;

        &.-lined {
            max-width: 70rem;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            padding: 0 1em;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                width: 99em;
                height: 1px;
                background-color: currentColor;
            }

            &::before {
                right: 100%;
            }

            &::after {
                left: 100%;
            }
        }

        &.-full {
            padding: 4rem 0;
            margin-top: -4rem;

            .section__title {
                margin-bottom: 0;
            }
        }

        &.-colored-primary {
            color: #fff;
        }

        &.-darkergray {
            background-color: $c-darkergray;
            color: #fff;
        }

        &.-white {
            color: #fff;
        }
    }

    &__title {
        font-size: 2.8rem;
        font-weight: 900;
        margin-bottom: .5em;

        strong {
            display: block;
            font-weight: 900;
            margin-top: .25em;
        }

        @include mq($from: desktop) {
            text-transform: uppercase;
        }
    }

    &__subtitle {
        font-family: $ff-serif;
        font-style: italic;
        font-size: 2rem;
        font-weight: normal;
        margin-top: .5em;
    }

    &__footer {
        margin-top: 2em;
    }

    @include mq($from: tablet) {
        &.-alt {
            .section__header {
                display: flex;
                justify-content: space-around;
            }

            .section__title {
                flex: 1 1 auto;
                justify-self: start;
                text-align: left;
            }

            .section__subtitle {
                flex: 1 1 auto;
                justify-self: flex-end;
                text-align: right;
            }
        }
    }

    @include mq($from: wide) {
        &__header {
            margin-bottom: 3rem;

            &.-full {
                margin-top: -5rem;
            }
        }
    }
}

/**
*
*/
[id="body"] {
    font-size: 0;
}

.exact {
    text-transform: none !important;
}

/*
* date on publications
*/

time {
    font-size: 1.4rem;
    color: $c-medgray;
    display: block;
    margin-top: 2rem;
}

.bold {
    font-weight: 700;
}

.underline {
    text-decoration: underline;
}

.italic {
    font-style: italic;
}

.no-pseudo::before, .no-pseudo::after {
    display: none;
}