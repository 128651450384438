/*------------------------------------*\
    #VOTE CALL TO ACTION
\*------------------------------------*/
.vote-cta {
    display: block;
    text-align: center;
    padding: 2.5em;
    max-width: 63rem;
    margin: 2rem auto;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-decoration: none;
    font-weight: bold;
    position: relative;

    svg {
        width: 3rem;
        height: 3rem;
        display: block;
        margin: 0 auto 1em;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid #fff;
        margin: 1rem;
        transition: border 200ms ease-in, margin 200ms ease-in;
    }

    &:hover,
    &:focus,
    &:active {
        &::after {
            border-width: 1rem;
            margin: .1rem;
        }
    }
}
