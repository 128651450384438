.scc-confirmation {
    &-box {
        position: fixed;
        left: 0;
        right: 0;
        top: -5rem;
        bottom: 0;
        z-index: 9998;
        opacity: 0;

        .scc-confirmation {
            &__content {
                top: 5rem;
                position: relative;
                margin: 0 auto;
                width: 420px;
                max-width: 80%;
                background-color: #fff;
                z-index: 9999;
                box-shadow: 0 2px 25px rgba(108, 108, 108, .4);
            }

            &__message {
                font-weight: bold;
                padding: 3rem 4rem 4rem;
                text-align: center;
            }

            &__pictogram {
                padding-top: 4rem;
                padding-left: 4rem;
                padding-right: 4rem;
                text-align: center;

                img {
                    height: 3rem;
                }
            }

            &__illustration {
                img {
                    width: 100%;
                }
            }

            &__time-bar {
                height: 3px;
                width: 100%;
                background-color: #333;
            }

            &__close {
                margin: 0 auto;
                padding: 0 2rem 3rem;
                text-align: center;

                &-link {
                    display: inline-block;
                    padding: .5rem 2rem;
                    font-weight: bold;
                    letter-spacing: .06em;
                    cursor: pointer;
                    border: 1px solid transparent;
                    border-radius: 25px;
                    transition: all 120ms ease;
                }
            }
        }

        @each $type, $color in $message-types {
            &.scc-confirmation-#{$type} {
                .scc-confirmation__time-bar {
                    background-color: $color;
                }

                .scc-confirmation__close-link {
                    color: $color;

                    &:hover {
                        border-color: $color;
                    }
                }
            }
        }
    }

    @include mq($until: desktop) {
        &-box {
            .scc-confirmation {
                &__content {
                    top: 5%;
                }
            }
        }
    }
}
