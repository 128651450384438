/*------------------------------------*\
    #MISC.

    - Add to favorites
    - Sharing toggle
\*------------------------------------*/


/**
* ADD TO FAVORITES
*/
.add-favorites {
    display: inline-block;
    opacity: .8;
    position: relative;
    color: $c-txt;
    text-align: center;
    z-index: 10;


    &__label {
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 2.5rem;
        text-transform: uppercase;
        font-size: 1rem;
        white-space: nowrap;
        height: 2.5rem;
        line-height: 2.5rem;
        padding-left: 1em;
        padding-right: 1em;
        background-color: #fff;
        overflow: hidden;
        transform: scaleX(0);
        transform-origin: 100% 50%;
        transition: transform 250ms ease-in;
    }

    &__icon {
        display: inline-block;
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        background-color: #fff;

        svg {
            width: 1.2rem;
            height: 1.2rem;
            vertical-align: inherit;
        }
    }



    /* Hover */
    &:hover,
    &:focus,
    &:active {
        opacity: 1;

        .add-favorites__icon {
            color: map-get($message-types, error);
        }

        .add-favorites__label {
            transform: scaleX(1);
        }
    }
}



/**
* SHARING TOGGLE
*/
.sharing-toggle {
    display: inline-block;
    opacity: .8;
    position: relative;
    color: $c-txt;
    text-align: center;
    z-index: 10;
    cursor: pointer;


    &__label {
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 2.5rem;
        text-transform: uppercase;
        font-size: 1rem;
        white-space: nowrap;
        height: 2.5rem;
        min-width: 18rem;
        line-height: 2.5rem;
        padding-left: 1em;
        padding-right: 1em;
        background-color: $c-medgray;
        overflow: hidden;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 100% 50%;
        -ms-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        transition: transform 250ms ease-in;

        a {
            color: #fff;
            display: inline-block;
            transition: color 150ms ease;
            margin: 0.35em 0.7em;
            font-size: .8em;
        }

        svg {
            width: 1.6rem;
            height: 1.6rem;
            vertical-align: inherit;
        }
    }

    &__icon {
        display: inline-block;
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        background-color: $c-medgray;

        svg {
            width: 1.2rem;
            height: 1.2rem;
            vertical-align: inherit;
            fill: #fff;
        }
    }


    /* Modifier */
    &.-big {
    }



    /* Hover */
    &:hover,
    &:focus,
    &:active {
        opacity: 1;

        .sharing-toggle__icon {
            background-color: $c-darkgray;
        }

        .sharing-toggle__label {
            -webkit-transform: scaleX(1);
            -ms-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
}
