.culture-page-list {
    padding-left: 0;
    list-style: none;
    margin-right: -1rem;
    margin-left: -1rem;

    li {
        padding: 1rem;
    }

    @include mq($until: tablet) {
        li + li {
            margin-top: 5rem;
        }
    }

    @include mq($from: tablet) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-right: -2rem;
        margin-left: -2rem;

        li {
            padding: 4rem 2rem;
            max-width: 43rem;

            &:nth-child(2n) {
                padding: 4rem 5rem 4rem 0;
            }
        }
    }
}

.culture-card {
    @include mq($from: mobile){
        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4rem;
        }
    }

    &__figure {
        margin-bottom: 3rem;
    }

    &__container, &--link {
        text-decoration: none;
        color: $c-txt;
        display: block;

        &:hover, &:focus {
            .culture-card__img {
                opacity: .7;
            }

            .culture-card__more {
                background-color: $c-txt;
                color: #fff;
            }
        }
    }

    &__title {
        font-size: 2.6rem;
        position: relative;
        margin-bottom: 1.5rem;

        &::before {
            content: '';
            display: inline-block;
            width: 3rem;
            height: .2rem;
            background: $c-darkergray;
            position: absolute;
            bottom: .8rem;
            left: -5rem;
        }
    }

    &__subtitle {
        font-size: 2rem;
        font-family: $ff-serif;
        font-weight: 400;
        margin-top: 0;
    }

    &__img {
        margin-bottom: 2rem;
        transition: opacity 300ms;
    }

    &__text {
        font-size: 1.8rem;
        margin-bottom: 3rem;
    }

    &__more {
        display: inline-block;
        text-decoration: none;
        border: 2px solid $c-txt;
        background-color: transparent;
        color: $c-txt;
        text-transform: uppercase;
        font-weight: bold;
        padding: 1.6rem 2.5rem;
        transition: background 300ms, color 300ms;
        text-align: center;
    }

    @include mq($until: tablet) {
        .culture-card__container {
            padding: 0 5.1rem;
        }
    }

    @include mq($from: tablet) {
        &__subtitle {
            margin-bottom: 4rem;
        }

        .btn-primary {
            font-size: 1.6rem;
            padding: 2rem 2.7rem;
        }

        // MODIFIERS
        // left to right + right to left
        &--ltr, &--rtl {
            display: flex;
            justify-content: space-between;

            .culture-card__figure {
                margin-bottom: 0;
            }

            .culture-card__figure, .culture-card__container {
                width: calc(50% - 3rem);
            }
        }

        // left ro right
        &--ltr {
            .culture-card__container {
                text-align: right;
                padding-right: 6%;

                &.culture-tiles {
                    padding-right: 0;
                }
            }

            .culture-card__title {
                &::before {
                    left: auto;
                    right: -5rem;
                }
            }
        }

        // right to left
        &--rtl {
            flex-direction: row-reverse;

            .culture-card__container {
                padding-left: 6%;

                &.culture-tiles {
                    padding-left: 0;
                }
            }
        }
    }

    @include mq($from: desktop) {
        &--ltr {
            .culture-card__container {
                padding-right: 5%;

                &.culture-tiles {
                    padding-right: 0;
                }
            }
        }

        &--rtl {
            .culture-card__container {
                padding-left: 5%;

                &.culture-tiles {
                    padding-left: 0;
                }
            }
        }
    }

    @include mq($from: wide) {
        &--ltr {
            .culture-card__container {
                padding-right: 9.5rem;

                &.culture-tiles {
                    padding-right: 0;
                }
            }
        }

        &--rtl {
            .culture-card__container {
                padding-left: 9.5rem;

                &.culture-tiles {
                    padding-left: 0;
                }
            }
        }
    }
}

.culture--link {
    color: $c-txt;
    font-family: $ff-serif;
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: underline;
    }
}
