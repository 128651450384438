/*------------------------------------*\
    #SERVICE DETAILS
\*------------------------------------*/
.service-details {
    background-color: #fff;
    padding: 2rem;

    .service-tile {
        width: 14rem;
        margin: 0 auto 2rem;

        &__icon {
            width: 7rem;
            height: 7rem;
        }
    }

    @include mq($from: tablet){
        .service-tile {
            display: inline-block;
            vertical-align: top;
            margin-bottom: 0;
        }

        &__content {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 20rem);
            margin-left: 5rem;
        }
    }

    @include mq($from: desktop){
        padding: 7rem 5rem;
    }
}