/*------------------------------------*\
    #AUTOCOMPLETE
\*------------------------------------*/

.autocomplete-suggestions {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
    overflow-y: scroll;
}

.autocomplete-suggestion {
    padding: .5em 1.2rem;

    &:not(:first-child) {
        border-top: 1px solid $c-lightgray;
    }

    &:hover {
        background-color: $c-lightergray;
        cursor: pointer;
    }
}

/* Selected - nav with keyboard */
.autocomplete-selected {
    background-color: $c-lightergray;
}

/* No results */
.autocomplete-no-suggestion {
    padding: .5em 1.2rem;
}