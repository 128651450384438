.paragraph {
    @include make-row();
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;


    /** Elements **/
    &__content,
    &__figure {
        padding: 0 $grid-gutter-width * .5em;
        flex: 1 0 100%;
    }

    &__content {
        order: 1;
        margin-top: 2rem;
    }

    &__header {
        @extend .section__header;
    }

    &__title {
        @extend .section__title;
    }

    &__subtitle {
        @extend .section__subtitle;
    }

    &__text {

    }



    /** Modifiers **/
    &.-rtl {
        .paragraph__figure {
            order: 0;
        }
    }

    .-culture & {
        .paragraph__text {
            margin-left: 7rem;
        }
    }


    /** Media Queries **/
    @include mq($from: tablet) {
        &__content,
        &__figure {
            flex-basis: 50%;
        }

        &__content {
            order: 0;
            margin-top: 0;
        }

        &.-rtl {
            .paragraph__content {
                order: 1;
            }
        }

        .-culture & {
            &.-rtl {
                .paragraph__title {
                    text-align: right;

                    &:before {
                        display: none;
                    }

                    &:after {
                        content: '';
                        display: inline-block;
                        width: 3rem;
                        border: .1rem solid $c-txt;
                        vertical-align: middle;
                        margin: 0 2rem;
                    }
                }

                .paragraph__subtitle {
                    text-align: right;
                }

                .paragraph__text {
                    margin-right: 7rem;
                    text-align: right;
                }
            }
        }
    }
}