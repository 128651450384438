.language-switcher {
    @extend %unstyled-list;

    > li {
        display: inline-block;
        padding: .5rem;
        
        span {
            color: #fff;
        }
    }

    @include mq($from: desktop) {
        > li:not(:first-child) {
            border-left: 1px solid rgba(153, 153, 153, .5);
            padding-left: 1rem;
            margin-left: .5rem;
        }
    }
}