/*------------------------------------*\
    #SHOP DETAILS PAGE
\*------------------------------------*/

/* Shop banner */
.shop-banner {
  margin-bottom: 3.5rem;

  &__media {
    position: relative;
  }

  /* Sharing */
  .sharing-toggle {
    position: absolute;
    top: 0rem;
    right: 0;
  }

  .btn-back {
    margin-bottom: 1rem;
  }
}

/* Shop description */
.shop-desc {
  position: relative;

  &__media {
    margin-bottom: 0.625em;

    img {
      width: 100%;
    }
  }

  /* Sharing */
  .sharing-toggle {
    position: absolute;
    top: 0rem;
    right: 0;
  }

  &__offers {
    margin: 0.5em 0;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    padding: .5em 1em;
    font-size: 2rem;
    font-weight: 900;
  }

  &__loyalty {
    margin: 0.5em 0;
    background-color: $c-darkgray;
    text-transform: uppercase;
    text-align: center;
    padding: .5em 1em;
    font-size: 2rem;
    font-weight: 900;

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    > * {
      vertical-align: middle;
    }
  }

  &__content {
    padding: 2rem;
    background-color: #fff;

    p,
    ul,
    ol {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 1.5em;
  }

  &__body {
    font-size: 1.5rem;

    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }

  &__subtitle {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 900;
  }

  &__schedule,
  &__calendar {
    max-width: 63rem;
    margin: 2rem auto;
    margin-left: 1rem;
  }

  &.-alt {
    .shop-desc__title {
      font-size: 6rem;
      margin-bottom: 3rem;
    }

    .shop-desc__media {
      margin-top: 4rem;
    }
  }

  .-kids & {
    .shop-desc__title {
      position: relative;
      font-family: $ff-sans-serif;
      font-weight: normal;
    }
  }

  @include mq($from: tablet) {
    &__schedule,
    &__calendar {
      padding: 3rem 5rem;
    }

    .-kids & {
      &__title {
        display: inline-block;
        margin-right: 8rem;
        padding: 7rem 0;
        position: relative;
        z-index: 0;

        &:before {
          content: "";
          position: absolute;
          background: url('../../images/kids/ink_6.png') no-repeat;
          width: 139px;
          height: 172px;
          top: 0;
          left: calc(100% - 6rem);
          z-index: -1;
        }
      }
    }
  }

  @include mq($from: desktop) {
    &__content {
      padding: 3em;
    }

    &.-alt {
      .shop-desc__media {
        margin-top: 0;
      }

      .shop-desc__body {
        padding-right: 4rem;
      }
    }
  }
}

/**
* Shop informations
* right column
*/
.shop-infos {
  padding: 4rem 2rem 2rem;
  background-color: #fff;
  position: relative;

  &__flags {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .logo-square__sales {
      max-width: 20rem;
    }
  }

  &__logo,
  &__infos,
  &__categories {
    z-index: 2;
    position: relative;
  }

  &__logo {
    font-size: 1em;
    margin-bottom: 3em;
    text-align: center;

    img {
      max-width: 20rem;
      max-height: 18rem;
    }
  }

  &__category {
    @include centerer(true, false);
    top: -2rem;
    display: inline-block;
    padding: .875em 2em;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    text-align: center;
    min-width: 25rem;
    white-space: nowrap;
    z-index: 2;

    svg {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 1em;
    }

    img {
      max-height: 4rem;
    }
  }

  &__infos {
    @extend %unstyled-list;
    padding: 1.5em 0;
    border-top: 1px solid $c-medgray;
    border-bottom: 1px solid $c-medgray;

    li {
      display: flex;

      + li {
        margin-top: .75em;
      }

      span.icon {
        display: inline-block;
        min-width: 5rem;
        text-align: center;

        > i {
          fill: $c-darkgray;
        }
      }
    }

    li.shop-infos__social {
      display: block;
    }

  }

  &__categories {
    border-top: 1px solid $c-medgray;
    padding-top: 1.5em;
  }

  &__social {
    padding-left: 0!important;

    > ul {
      @extend %unstyled-list;
      text-align: left;
      display: inline-block;

      li {
        display: inline-block;
        padding-left: 0;
        margin: 0 1rem  0 0;
      }

      a {
        color: $c-darkgray;
      }
    }
  }

  &__map {
    position: relative;
    padding: 2rem 0;
    text-align: center;

    img {
      margin: 1.5em 0;
      display: block;
    }
  }

  &.-game {
    .form-field {
      input {
        width: 100%;
      }
    }

    .game-form__submit {
      margin-top: 1.6rem;
    }
  }

  @include mq($from: tablet) {
      margin-left: 1rem;
  }

  @include mq($from: desktop) {
    padding: 6rem 4rem 4rem;
  }
}

/* SERVICES */
.shop-services {
  @extend %unstyled-list;
  font-size: 1.4rem;
  font-family: $ff-serif;
  margin: 1em 0;

  li {
    padding: .25em 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__icon {
    display: inline-block;
    vertical-align: middle;
    width: 6rem;
    height: 6rem;
    position: relative;
    border-radius: .5em;
  }

  &__label {
    display: inline-block;
    vertical-align: middle;
    margin-left: 2rem;
    width: calc(100% - 9rem);
  }
}


.shop-sidebar {
  @include mq($until: tablet) {
    margin-top: 2rem;
  }

  @include mq($from: tablet) {
    .event-cta,
    .vote-cta,
    .newsletter-cta {
      margin-left: 1rem;
    }
  }
}
