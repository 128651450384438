/*------------------------------------*\
    #HOMEPAGE
\*------------------------------------*/

/**
* HERO BANNER (video on desktop, image on mobile)
*/
.hero-banner {
    @include flexbox();
    flex-flow: column;
    min-height: 40rem;
    overflow: hidden;
    position: relative;
    z-index: 5;
    justify-content: center;
    text-align: center;
    color: #fff;

    &__content {
        padding: 4rem 2rem 2rem;
        position: relative;

        &.sr-only {
            padding: 0;
            position: absolute;
        }
    }

    
    &__play {
        display: none;
        background: none;
        border: none;
        color: #fff;
        font-size: 1.8rem;
        width: 100%;
        text-align: right;
        outline: none;
        position: absolute;
        z-index: 100;
        top: 2rem;
        right: 2rem;
        
        svg {
            margin-left: .5rem;
            width: 4rem;
            height: 4rem;
        }
    }
    
    &__title {
        font-size: 2.25em;
        margin-bottom: 0;
    }

    &__title + &__subtitle {
        &::before {
            content: '';
            display: block;
            width: 90%;
            max-width: 49rem;
            height: .3rem;
            background-color: #fff;
            margin: 2.5rem auto;
        }
    }
    
    &__subtitle {
        font-family: $ff-serif;
        font-style: italic;
        font-size: 1.5em;
        margin: 0 auto 1.5em;
        max-width: 20em;
    }
    
    .no-objectfit &__video {
        @include cover-image();
        z-index: -1;

        @include mq($until: tablet) {
            height: 100%;
        }
    }

    .objectfit &__video-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    .objectfit &__video {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    
    &__scrollto {
        color: #fff;
        margin: 1.5em auto 2em;
        display: block;
        
        svg {
            width: 3.2rem;
            height: 3.2rem;
        }
    }

    &__full {
        cursor: pointer;
    }

    .quick-infos {
        max-width: $container-mw;
        margin-left: auto;
        margin-right: auto;
    }

    @include mq($from: tablet) {
        height: 73rem;
        
        &__title {
            font-size: 3.5em;
        }
        
        &__subtitle {
            font-size: 2.25em;
        }
        
        .quick-infos {
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    
    @include mq($from: desktop) {
        height: 67rem;
    }
}

#hero-slideshow {
    height: 100%;
}

#hero-slideshow .slick-list.draggable, .slick-slide, .slick-track, .slick-slide > div, .hero-slideshow__slide {
    height: 100%;
}

#hero-slideshow .hero-slideshow__slide img {
    object-fit: cover;
    width: 100%;
    height: 40rem;
}

#hero-slideshow {
    margin-bottom: 40px;
    flex: 1;
}

@include mq($from: desktop) {
    #hero-slideshow .hero-slideshow__slide img {
        height: 100%;
    }

    #hero-slideshow .slick-dots {
        bottom: 25px
    }

    #hero-slideshow {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}