.foodcourt-hours {
    background-color: #fff;
    position: relative;
    padding: 4rem;
    margin-top: $grid-gutter-width * 1em;
    z-index: 1;

    &::before {
        content: '';
        margin: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px solid #000;
        z-index: -1;
    }

    &__title {
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 1.5em;
    }

    &__days {
        @extend %unstyled-list;
    }

    &__carousel {
        margin-top: 4rem;
        padding: 0 2.4rem;

        .slick-prev,
        .slick-next {
            border: 0;
            background-color: transparent;
            padding: 0;
            margin: 0;
            position: absolute;
            top: 50%;
            width: 2.4rem;
            height: 2.4rem;
            transform: translateY(-50%);
            @extend %hide-text;

            &:before {
                content:'';
                display: block;
                width: 2.4rem;
                height: 2.4rem;
                -webkit-background-size: 2.4rem 2.4rem;
                background-size: 2.4rem 2.4rem;
            }
        }

        .slick-prev {
            left: 0;

            &:before {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj4gICAgPGcgaWQ9Imljb24tYW5nbGUtbGVmdCI+ICAgICAgICA8cGF0aCBkPSJtMzQ0IDE1NWMwIDMtMSA1LTMgN2wtMTEyIDExMmwxMTIgMTEzYzIgMSAzIDQgMyA2YzAgMy0xIDUtMyA3bC0xNCAxNGMtMiAyLTUgMy03IDNjLTIgMC01LTEtNy0zbC0xMzMtMTMzYy0yLTItMy00LTMtN2MwLTIgMS00IDMtNmwxMzMtMTMzYzItMiA1LTMgNy0zYzIgMCA1IDEgNyAzbDE0IDE0YzIgMiAzIDQgMyA2eiIvPiAgICA8L2c+PC9zdmc+);
            }
        }

        .slick-next {
            right: 0;

            &:before {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj4gICAgPGcgaWQ9Imljb24tYW5nbGUtcmlnaHQiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj4gICAgICAgIDxwYXRoIGQ9Im0zMzUgMjc0YzAgMy0xIDUtMyA3bC0xMzMgMTMzYy0yIDItNSAzLTcgM2MtMiAwLTUtMS03LTNsLTE0LTE0Yy0yLTItMy00LTMtN2MwLTIgMS01IDMtNmwxMTItMTEzbC0xMTItMTEyYy0yLTItMy00LTMtN2MwLTIgMS00IDMtNmwxNC0xNGMyLTIgNS0zIDctM2MyIDAgNSAxIDcgM2wxMzMgMTMzYzIgMiAzIDQgMyA2eiIvPiAgICA8L2c+PC9zdmc+);
            }
        }

        .slick-slide,
        > img {
            margin: $grid-gutter-width * 1em;
        }

        .slick-track {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
        }

        .slick-slide {
            text-align: center;

            img {
                vertical-align: middle;
                display: inline-block;
            }
        }
    }

    @include mq($until:tablet) {
        &__title {
            text-align: center;

            &:first-child {
                margin-top: 1.5em;
            }
        }

        &__days {
            text-align: center;
        }

        &__carousel {
            margin-top: 2rem;
        }
    }

}
