.testimonial {
    &__wrapper {
        width: 100%;
        right: 0;
        position: relative;
    }

    &__item {
        outline: none;
        cursor: pointer;
    }

    &__cards {
        .slick-slide {
            &.slick-current {
                .testimonial__item {
                    opacity: 1;
                }
            }

            &:not(.slick-current) {
                .testimonial__item {
                    color: #999;
                }
            }

            width: 400px;
            margin: 2rem 3rem 3rem;
        }

        .testimonial__item {
            color: $c-darkgray;
            background-color: #fff;
            padding: 3rem 4rem;
            opacity: .75;
            text-align: center;
            -webkit-transition: all .5s linear;
            -o-transition: all .5s linear;
            transition: all .5s linear;
            box-shadow: 0 3px 30px rgba(202, 202, 202, 0.2);
            display: flex !important;
            flex-direction: column;

            &.slick-current {
                opacity: 1;
            }

            &-thumbnail {
                text-align: center;

                > img {
                    height: 15px;
                    margin: 0 auto;
                    text-align: center;
                }
            }

            &-message {
                margin-top: 2rem;
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-author {
                margin-top: 2rem;
                font-size: 13px;
                font-weight: bold;
                text-transform: uppercase;

                &::before {
                    content: '\2013';
                }
            }
        }
    }

    @include mq($until: tablet) {
        &__cards {
            .slick-slide {
                width: 320px;
            }
        }

        .testimonial__wrapper:after, .testimonial__wrapper:before {
            width: 0 !important;
            height: 0 !important;
            background: none !important;
        }
    }

    &__dots {
        display: block;
        width: 100%;
        padding: 0;
        margin: 2rem 0 0;
        list-style: none;
        text-align: center;

        > li {
            display: inline-block;
            vertical-align: middle;
            width: 1rem;
            height: 1rem;
            background-color: rgba(138, 197, 215, .7);
            text-indent: 200%;
            white-space: nowrap;
            border-radius: 50%;
            cursor: pointer;
            margin: 0 1rem;

            &.slick-active {
                > button {
                    background-color: rgba(138, 197, 215, 1);
                }
            }

            > button {
                font-size: 0;
                line-height: 0;
                display: block;
                width: 10px;
                height: 10px;
                padding: 5px;
                cursor: pointer;
                color: transparent;
                outline: none;
                background: transparent;
                border: 0;
                border-radius: 50%;
            }
        }
    }
}

.testimonial__wrapper:after, .testimonial__wrapper:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 125px;
    height: 100%;
    z-index: 1;
    background: -moz-linear-gradient(left, rgba(243, 243, 243, 1) 52%, rgba(243, 243, 243, 0.06) 90%, rgba(243, 243, 243, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(243, 243, 243, 1) 52%, rgba(243, 243, 243, 0.06) 90%, rgba(243, 243, 243, 0) 100%);
    background: linear-gradient(to right, rgba(243, 243, 243, 1) 52%, rgba(243, 243, 243, 0.06) 90%, rgba(243, 243, 243, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#00f9f9f9', GradientType=1);
}

.testimonial__wrapper:after {
    left: auto;
    right: 0;
    background: -moz-linear-gradient(right, rgba(243, 243, 243, 1) 52%, rgba(243, 243, 243, 0.06) 90%, rgba(243, 243, 243, 0) 100%);
    background: -webkit-linear-gradient(right, rgba(243, 243, 243, 1) 52%, rgba(243, 243, 243, 0.06) 90%, rgba(243, 243, 243, 0) 100%);
    background: linear-gradient(to left, rgba(243, 243, 243, 1) 52%, rgba(243, 243, 243, 0.06) 90%, rgba(243, 243, 243, 0) 100%);
}

.-white {
    .testimonial__wrapper:after, .testimonial__wrapper:before {
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 52%, rgba(255, 255, 255, 0.06) 90%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 52%, rgba(255, 255, 255, 0.06) 90%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to right, rgba(255, 255, 255, 1) 52%, rgba(255, 255, 255, 0.06) 90%, rgba(255, 255, 255, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#00f9f9f9', GradientType=1);
    }

    .testimonial__wrapper:after {
        background: -moz-linear-gradient(right,rgba(255, 255, 255,1) 52%,rgba(255, 255, 255,0.06) 90%,rgba(255, 255, 255,0) 100%);
        background: -webkit-linear-gradient(right,rgba(255, 255, 255,1) 52%,rgba(255, 255, 255,0.06) 90%,rgba(255, 255, 255,0) 100%);
        background: linear-gradient(to left,rgba(255, 255, 255,1) 52%,rgba(255, 255, 255,0.06) 90%,rgba(255, 255, 255,0) 100%);
    }
}

.-darkgray {
    .testimonial__wrapper:after, .testimonial__wrapper:before {
        background: -moz-linear-gradient(left, rgba(56, 56, 56, 1) 52%, rgba(56, 56, 56, 0.06) 90%, rgba(56, 56, 56, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(56, 56, 56, 1) 52%, rgba(56, 56, 56, 0.06) 90%, rgba(56, 56, 56, 0) 100%);
        background: linear-gradient(to right, rgba(56, 56, 56, 1) 52%, rgba(56, 56, 56, 0.06) 90%, rgba(56, 56, 56, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#00f9f9f9', GradientType=1);
    }

    .testimonial__wrapper:after {
        background: -moz-linear-gradient(right,rgba(56, 56, 56,1) 52%,rgba(56, 56, 56,0.06) 90%,rgba(56, 56, 56,0) 100%);
        background: -webkit-linear-gradient(right,rgba(56, 56, 56,1) 52%,rgba(56, 56, 56,0.06) 90%,rgba(56, 56, 56,0) 100%);
        background: linear-gradient(to left,rgba(56, 56, 56,1) 52%,rgba(56, 56, 56,0.06) 90%,rgba(56, 56, 56,0) 100%);
    }
}
