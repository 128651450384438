/*------------------------------------*\
    #CONTACT
\*------------------------------------*/

/* Form */
.contact-form {
    background-color: #fff;
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    padding: 2rem;

    &__title {
        text-align: center;
        text-transform: uppercase;
        font-size: 2.2rem;
        margin-bottom: 2em;
    }

    .form-field {
        @extend %clearfix;
        max-width: 57rem;
        margin: 1em auto;

        > label:not(.sr-only) {
            font-size: 1.4rem;
            font-weight: bold;
            text-transform: uppercase;
        }

        label {
            input[type="text"] {
                width: auto;
            }
        }
    }

    .form-action {
        margin-top: 2em;
    }

    [type="text"],
    [type="email"],
    [type="tel"],
    select,
    textarea {
        width: 100%;
    }

    [type="checkbox"] {
        + label {
            font-size: 1.4rem;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    hr  {
        max-width: 57rem;
        margin: 1em auto;
    }

    input:-moz-placeholder,
    input::-moz-placeholder,
    textarea:-moz-placeholder,
    textarea::-moz-placeholder {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $c-medgray;
    }

    ::-webkit-input-placeholder {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $c-medgray;
    }


    @include mq($from: tablet){
        padding: 4em;
    }
}

.contact-navigation {
    @extend .row;

    width: 100%;
    max-width: 100rem;
    margin: 0 auto 2rem;

    > a {
        padding: 2rem;
        background-color: #ffffff;
        color: $c-darkergray;
        text-decoration: none;
        transition: all .3s ease-in-out;
        display: block;

        div {
            margin-left: 2rem;

            &:first-of-type {
                font-weight: bold;
                font-size: 1em;
                text-transform: uppercase;
            }

            &:last-of-type {
                font-size: .9em;
            }
        }


        &.active {
            box-shadow: 0 20px 20px -20px rgba(147, 195, 233, .7);
        }

        &:not(.active) {
            background-color: rgba(#ffffff, .6);
            //color: lighten(#1e1e1e, 30%);
            color: #999999;
        }

        &:hover {
            &:not(.active) {
                background-color: rgba(#ffffff, 1);
            }
        }
    }

    &__column_2 {
        > a {
            @extend .col-sm-6;
            @extend .col-xs-12;
        }
    }

    &__column_3 {
        > a {
            @extend .col-sm-4;
            @extend .col-xs-12;
        }
    }
}

.form-field__date {
    input[type=text] {
        width: 6rem !important;

        &[name=year] {
            width: 8rem !important;
        }
    }
}

/* Styles for the floating WhatsApp button */
.whatsapp-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25D366;
    color: #FFF;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.whatsapp-float:hover {
    background-color: #1DA851;
}

.whatsapp-float i {
    font-size: 30px;
    color: #FFF;
}

.whatsapp-box {
    position: fixed;
    bottom: 110px;
    right: 40px;
    background-color: white;
    color: #333;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    padding: 10px;
    z-index: 999;
    width: 300px;
    opacity: 0;
    transform: scale(0.8);
    transform-origin: bottom right;
    transition: opacity 0.3s, transform 0.3s;
    user-select: none;
    display: none;
}

.whatsapp-box.active {
    display: block;
    opacity: 1;
    transform: scale(1);
}

.whatsapp-box a {
    color: #25D366;
    text-decoration: none;
    font-weight: bold;
}
