.aside {
    padding: 0 2.3rem;
    margin-top: 2.7rem;

    &__wrapper.--scroll {
        @include mq($from: tablet) {
            .aside {
                position: relative;
                min-height: 1px;

                &__content {
                    position: absolute;
                    width: 100%;
                }
            }
        }
    }

    section {
        &.--post-separator {
            position: relative;

            &:after {
                content: '';
                width: 50%;
                left: 50%;
                height: 1px;
                position: absolute;
                background-image: linear-gradient(to right, #bbc4c7 40%, rgba(255, 255, 255, 0) 0%);
                background-position: top;
                background-size: 7px 1px;
                background-repeat: repeat-x;
                margin-left: -25%;
                bottom: 0;
            }
        }

        .-lined {
            &:before {
                right: initial;
                content: none;
            }

            &:after {
                left: initial;
                content: none;
            }
        }

        > h1, > h2, > header h1, > header h2 {
            font-size: .9em;
            text-transform: uppercase;
            font-weight: bold;
            position: relative;
            padding-bottom: 1em;
            margin-bottom: 1em;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                overflow: hidden;
                width: 15px;
                height: 1px;
                border-top: 1px solid;
                left: 50%;
                margin-left: -8px;
            }
        }
    }

    h1, h2, h3, h4 {
        font-weight: normal;
        text-align: center;
    }

    &--right {

    }

    &--left {

    }

    &--content {
        padding: 0 2.3rem;
    }
}
