/*------------------------------------*\
    #AUTH
\*------------------------------------*/


/* Form */
.auth-form {
    background-color: #fff;
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    padding: 2rem;

    &__title {
        text-align: center;
        text-transform: uppercase;
        font-size: 2.2rem;
        margin-bottom: 2em;
    }

    .form-field {
        @extend %clearfix;
        max-width: 57rem;
        margin: 1em auto;

        > label:not(.sr-only) {
            font-size: 1.4rem;
            font-weight: bold;
            text-transform: uppercase;
        }

        label {
            input[type="text"] {
                width: auto;
            }
        }
    }

    .form-action {
        margin-top: 2em;
    }

    select,
    [type="password"] {

        width: 100%;
    }

    input:-moz-placeholder,
    input::-moz-placeholder
 {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $c-medgray;
    }

    ::-webkit-input-placeholder {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $c-medgray;
    }


    @include mq($from: tablet){
        padding: 4em;
    }
}
