/*------------------------------------*\
    #GRID SYSTEM
\*------------------------------------*/

// Container widths
.container {
    @include container-fixed;

    @media (min-width: $screen-sm-min) {
        width: $container-sm;
    }
    @media (min-width: $screen-md-min) {
        width: $container-md;
    }
    @media (min-width: $screen-lg-min) {
        width: $container-lg;
    }
}


// Fluid container
.container-fluid {
    @include container-fixed;
    max-width: $container-mw;

    &-sm {
        max-width: $container-sm-mw;
    }

    &-imaged {
        background-position: top center;
        background-size: cover;
    }
}

.-container-filled {
    @include mq($until: tablet) {
        .container-fluid {
            margin-left: 2rem;
            margin-right: 2rem;
        }
    }
}


// Row
.row {
    @include make-row;
    @extend %clearfix;
}


// Columns
@include make-grid-columns;


// Extra small grid
@include make-grid(xs);


// Small grid
@media (min-width: $screen-sm-min) {
    @include make-grid(sm);
}


// Medium grid
@media (min-width: $screen-md-min) {
    @include make-grid(md);
}


// Large grid
@media (min-width: $screen-lg-min) {
    @include make-grid(lg);
}
