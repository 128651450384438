.block-head {
    &--transition {
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
    }

    background-color: #fff;

    &__figure {
        overflow: hidden;
        height: 16rem;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }

    &__content {
        padding: 4rem 0;
    }

    &__title {
        font-size: 4.8rem;
        font-family: $ff-sans-serif;
        font-weight: normal;
        color: $c-darkergray;
        text-align: center;

        a {
            color: $c-darkergray;
            text-decoration: none;
            @extend .block-head--transition;

            &:hover,
            &:focus,
            &:active {
                color: lighten($c-darkergray, 25%);
            }
        }
    }

    .baseline {
        color: rgb(110, 198, 212);
    }

    &__text {
        max-width: 68rem;
        margin: auto;
        text-align: center;
    }

    &.-kids {
        .block-head__content {
            text-align: center;
            min-height: 24.5rem;
        }

        .block-head__title {
            display: inline-block;
            position: relative;
            z-index: 1;

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                -webkit-background-size: cover;
                background-size: cover;
            }

            &:after {
                background-image: url('../../images/kids/balloons_6.png');
                width: 139px;
                height: 176px;
                top: -9rem;
                left: calc(100% - 3.2rem);
                z-index: -1;
            }
        }
    }

    &__blog {
        padding: 0 0 4rem;

        .block-head {
            &__subtitle {
                text-align: center;

                ul {
                    @extend .unstyled-list;

                    li {
                        @extend .unstyled-list;
                        font-size: .9em;
                        text-transform: uppercase;
                        display: inline-block;
                        padding-bottom: .8rem;
                        @extend .summary-card__blog--transition;

                        a {
                            text-decoration: none;
                            color: #3991cf;
                        }

                        &:not(:last-of-type) {
                            &:after {
                                content: '-';
                                padding-left: .8rem;
                                padding-right: .6rem;
                            }
                        }
                    }
                }
            }

            &__title {
                text-transform: uppercase;
                font-size: 4rem;
            }
        }
    }

    &.--overlay {
        position: relative;

        .block-head {
            &__figure {
                position: absolute;
                width: 100%;
            }

            &__content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                flex: 1 1 0;
            }

            &__text {
                margin: 0;
            }

            &__title, &__text {
                color: #fff;
            }
        }

        > .container-fluid {
            position: relative;
        }
    }

    @include mq($until: mobile) {
        &.--overlay {
            .block-head {
                &__figure {
                    height: auto;
                }
            }
        }
    }

    @include mq($until: tablet) {
        &.-kids {
            .block-head__title {
                &:after {
                    display: none;
                }
            }
        }
    }

    @include mq($from: tablet) {
        &__figure {
            height: 25rem;
        }

        &__title {
            margin-bottom: 3rem;
            font-size: 6rem;
        }

        &__text {
            font-size: 1.8rem;
        }
    }

    @include mq($from: desktop) {
        &__figure {
            height: 25rem;
        }
    }
}
