.agenda {
    &__listing {
        > ul {
            @extend .listing;

            > li {
                @extend .list-card;

                text-align: left;
                padding: 3em;

                h4, .h4 {
                    font-weight: normal;
                    letter-spacing: .1rem;
                }

                .agenda__content {
                    //box-shadow: 0 3px 17px 2px rgb(245, 245, 245);
                    margin: .9rem 1rem;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    align-content: center;
                    border: 1px solid rgb(245, 245, 245);

                    &.disabled {
                        opacity: .4;
                        //box-shadow: 0 3px 8px 2px rgb(245, 245, 245);
                    }

                    &-picto {
                        color: #333;
                        padding: 2rem;
                    }

                    &-text {
                        padding: 2rem 2rem 2rem 0;
                    }
                }
            }
        }
    }

    &__calendar {
        padding: 3rem 4rem;
        background: #fff;

        /**
         * Full Calendar override
         */
        .js-fullcalendar {
            &.fc {
                h2 {
                    font-size: 2.5rem;
                    font-weight: normal;
                    text-transform: capitalize;
                }

                .fc-button {
                    @extend .btn;
                    border-radius: 0;
                    background: none;

                }

                .fc-widget-header {
                    border: 0;

                    .fc-day-header {
                        border: none;
                        padding: 1rem 0;
                    }
                }

                .fc-day-grid-event {
                    border: none;
                    transition: box-shadow 0.1s ease-in-out;

                    &:hover {
                        box-shadow: 0 3px 5px #dfdfdf;
                    }

                    &.-color1 {
                        background-color: #f696b8;
                    }
                    &.-color2 {
                        background-color: #f7c3b0;
                    }
                    &.-color3 {
                        background-color: #69deab;
                    }
                    &.-color4 {
                        background-color: #c8d04b;
                    }
                    &.-color5 {
                        background-color: #175388;
                    }
                    &.-color6 {
                        background-color: #685588;
                    }
                    &.-color7 {
                        background-color: #881000;
                    }
                }
            }
        }
    }
}
